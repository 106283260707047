package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.packet.work.*
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm.dto.work.WorkTypeDTO
import ru.arty_bikini.crm_frontend.ClientCore
import kotlin.js.Date

class WorkService(clientCore: ClientCore) : BaseService(clientCore) {

    suspend fun addWork(order: OrderDTO, user: UserDTO, interval: IntervalDTO, types: List<WorkTypeDTO>, priority: Int): AddWorkResponse? {
        return call<AddWorkResponse, AddWorkRequest>("/api/work/add-work", body = AddWorkRequest(WorkDTO().apply {
            this.order = order
            this.user = user
            this.interval = interval
            this.works = types
            this.priority = priority
        })).body
    }

    suspend fun addInterval(endDate: Date): AddIntervalResponse? {
        return call<AddIntervalResponse, AddIntervalRequest>("/api/work/add-interval", body = AddIntervalRequest(endDate.getTime().toLong())).body
    }

    suspend fun editInterval(interval: IntervalDTO, endDate: Date): EditIntervalResponse? {
        return call<EditIntervalResponse, EditIntervalRequest>(
            "/api/work/edit-interval",
            body = EditIntervalRequest(interval.id, endDate.getTime().toLong())
        ).body
    }

    suspend fun editWork(entity: WorkDTO): EditWorkResponse? {
        return call<EditWorkResponse, EditWorkRequest>("/api/work/edit-work", body = EditWorkRequest(entity)).body
    }

    suspend fun editWorkProgress(entity: WorkDTO): EditWorkResponse? {
        return call<EditWorkResponse, EditWorkRequest>("/api/work/edit-work-progress", body = EditWorkRequest(entity)).body
    }

    suspend fun delWork(entity: WorkDTO): DelWorkResponse? {
        return call<DelWorkResponse, DelWorkRequest>("/api/work/del-work", body = DelWorkRequest(entity.id)).body
    }

    suspend fun divInterval(interval: IntervalDTO, tourDate: Date): DivIntervalResponse? {
        return call<DivIntervalResponse, DivIntervalRequest>("/api/work/div-interval", body = DivIntervalRequest(interval.id, tourDate.getTime().toLong())).body
    }

    suspend fun assignWorkToTour(work: WorkDTO): AssignWorkToTourResponse? {
        return call<AssignWorkToTourResponse, AssignWorkToTourRequest>("/api/work/assign-work-to-tour", body = AssignWorkToTourRequest(work.id)).body
    }

    suspend fun delWorkFromTour(work: WorkDTO): DelWorkFromTourResponse? {
        return call<DelWorkFromTourResponse, DelWorkFromTourRequest>("/api/work/del-work-from-tour", body = DelWorkFromTourRequest(work.id)).body
    }

    suspend fun delInterval(interval: IntervalDTO): DelIntervalResponse? {
        return call<DelIntervalResponse, DelIntervalRequest>("/api/work/del-interval", body = DelIntervalRequest(interval.id)).body
    }

    suspend fun getIntervalWork(endDate: Date): GetIntervalWorkResponse? {
        return call<GetIntervalWorkResponse, GetIntervalWorkRequest>(
            "/api/work/get-interval-work",
            body = GetIntervalWorkRequest(endDate.getTime().toLong())
        ).body
    }

}
