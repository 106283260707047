package ru.arty_bikini.crm_frontend.ui.input.form

import csstype.ClassName
import csstype.Overflow
import react.ChildrenBuilder
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.ui.bootstrap.InputBlockDSL
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.input.types.CostValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.DateValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.IntValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.StringValueConverter
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.percent
import ru.arty_bikini.crm_frontend.util.useCache

external interface FormInputProps<T> : ClientProps {
    var entity: T
    var dsl: FormInputDSL<T>
}

private val FormInput = tryFC<FormInputProps<*>>() { props ->

    val bounded = props as FormInputProps<Any?>

    val orderDataTypes = useCache(props.client.cache.orderDataTypes)

    props.dsl.orderDataTypes = orderDataTypes

    bounded.dsl.content(this, props.entity)

}

fun <T> ChildrenBuilder.FormInput(client: ClientCore, entity: T, init: FormInputDSL<T>.() -> Unit) {
    FormInput {


        val props = this.unsafeCast<FormInputProps<T>>()

        props.client = client
        props.entity = entity

        val dsl = FormInputDSL<T>(client, entity)
        init(dsl)

        props.dsl = dsl

    }
}

class FormInputDSL<T>(private val client: ClientCore, val entity: T) {

    var orderDataTypes: List<OrderDataTypeDTO> = emptyList()

    var content: ChildrenBuilder.(T) -> Unit = {}
        private set

    var onSave: suspend (T) -> Unit = {}
        private set

    var readOnly: Boolean = false

    val InputBlockDSL.addUser: (T.() -> UserDTO?) -> Unit
        get() = { this@addUser.value(it(entity)?.login) }

    val InputBlockDSL.addText
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, StringValueConverter)
    val InputBlockDSL.addInt
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, IntValueConverter)
    val InputBlockDSL.addCost
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, CostValueConverter)
    val InputBlockDSL.addDate
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, DateValueConverter)


    val InputBlockDSL.addTextArea
        get() = FormInputBuilderTextArea(this.cb, entity, readOnly, onSave)

    val InputBlockDSL.addSelect
        get() = FormInputBuilderSelect(this.cb, entity, readOnly, onSave)

    val ChildrenBuilder.addImages
        get() = FormInputBuilderImage(client, this, entity as? OrderDTO, readOnly)

    fun content(init: ChildrenBuilder.(T) -> Unit) {
        this.content = init
    }

    fun onSave(action: suspend (T) -> Unit) {
        this.onSave = action
    }

}


val FormInputDSL<OrderDTO>.addSpecial: InputBlockDSL.(flag: FieldFlag.FromGoogleForms, type: MeasureType<SpecialMeasure>, extra: SpecialMeasure) -> Unit
    get() = { flag, type, extra, ->
        value(
            text = this@addSpecial.entity.getMeasure(orderDataTypes, type, extra),
            color = ClassName("bg-transparent"),
            style = { maxWidth = 30.percent; overflow = Overflow.hidden }
        )
    }

private fun <T : Enum<T>> OrderDTO.getMeasure(orderDataTypes: List<OrderDataTypeDTO>, type: MeasureType<T>, extra: T): String? {

    val key =
            orderDataTypes
            .find { it.displayCategory == type.category && it.displayPosition == extra.name }
            ?: return null

    return dataGoogle
        ?.data
        ?.get(key.id)

}

