package ru.arty_bikini.crm_frontend.ui.md

import csstype.ClassName
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import react.ChildrenBuilder
import react.Props
import react.createRef
import react.dom.html.ReactHTML.div
import react.useEffect
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface MarkdownComponentProps : Props {
    var data: String
}

private val MarkdownComponent = tryFC<MarkdownComponentProps> { props ->

    val ref = createRef<HTMLDivElement>()

    useEffect(props.data) {
        try {
            ref.current?.apply {
                innerHTML = window.asDynamic().marked.parse(props.data)
            }
        } catch (e: Throwable) {
            e.printStackTrace()
        }
    }

    div {
        className = ClassName("w-100 h-100")

        this.ref = ref


    }
}

fun ChildrenBuilder.MarkdownComponent(data: String) {
    MarkdownComponent {
        this.data = data
    }
}