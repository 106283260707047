package ru.arty_bikini.crm_frontend.ui.input.panel

import csstype.ClassName
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.useState
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import kotlin.js.Date

external interface InputPanelItemProps : Props {
    var item: InputPanelItem<*>
    var update: (InputPanelValueKey<*>, Any?) -> Unit
}

val InputPanelRow = tryFC<InputPanelItemProps>{ props ->

    fun <T : Any> bounded(item: InputPanelItem<T>, update: (InputPanelValueKey<T?>, T?) -> Unit) {
        var value: String? by useState(item.defaultValueAsString)

        when (props.item.type) {
            InputPanelItemType.TEXT -> {
                ReactHTML.input {
                    className = ClassName("form-control")

                    this.value = value ?: ""

                    onChange = {
                        val newString = it.currentTarget.value

                        value = newString

                        update(item.key, newString.unsafeCast<T?>())
                    }
                }
            }

            InputPanelItemType.INT -> {
                ReactHTML.input {
                    className = ClassName("form-control")

                    type = InputType.number

                    this.value = value ?: ""

                    onChange = {
                        val newString = it.currentTarget.value

                        value = newString

                        update(item.key, newString.toIntOrNull().unsafeCast<T?>())
                    }
                }
            }

            InputPanelItemType.DATE -> {
                ReactHTML.input {
                    className = ClassName("form-control")

                    type = InputType.date

                    this.value = value ?: ""

                    onChange = {
                        val newString = it.currentTarget.value

                        value = newString

                        update(item.key, Date.parse(newString).unsafeCast<T?>())
                    }
                }
            }

            InputPanelItemType.PASSWORD -> {
                ReactHTML.input {
                    className = ClassName("form-control")

                    type = InputType.password

                    onChange = {
                        val new = it.currentTarget.value

                        value = new

                        update(item.key, new.unsafeCast<T>())
                    }
                }
            }
        }
    }

    bounded(props.item, props.update)

}
