package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDivElement
import react.createRef
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.useState
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*
import kotlin.js.Date

external interface AddWorkButtonProps : ClientProps {
    var orders: List<OrderDTO>

    var user: UserDTO
    var interval: IntervalDTO
    var nextWorkId: Int

    var reloadAll: () -> Unit

}

val AddWorkButton = tryFC<AddWorkButtonProps> { props ->

    val workTypes = useCache(props.client.cache.workTypes)

    var query by useState("")
    var actualRemaining by useState(20)

    val dropdownRef = createRef<HTMLDivElement>()

    dropdown(
        ref = dropdownRef,
        buttonTitle = {
            button {
                className = BootstrapButton.outline(BootstrapColor.SECONDARY) + ClassName("m-1")
                +"Добавить"
            }
        },
        buttonAsDiv = true
    ) {
        div {
            className = ClassName("px-2")

            inputGroup {
                name("Поиск")

                with(cb) {
                    input {
                        className = ClassName("form-control form-control-sm")

                        value = query

                        onChange = {
                            query = it.target.value
                        }
                    }
                }
            }

        }
        dropdownDivider()


        var remaining = actualRemaining

        val seenIds = ArrayList<Int>()

        fun renderOrderItem(selected: OrderDTO) {
            dropdownItem(
                content = {
                    div {
                        className = ClassName("vstack gap-1")

                        div {
                            className = ClassName("hstack gap-1")
                            val search = StringUtils.isSimilar(query, selected.name ?: "")

                            div {
                                className = ClassName("flex-grow-1")
                                search.render(this)
                            }

                            div {
                                val packageTime = selected.personalData().packageTime
                                    ?.let { Date(it) }
                                    ?.toISOString()
                                    ?.split("T")
                                    ?.firstOrNull()

                                badge(
                                    color = if (packageTime == null) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                    text = "Нужен ${packageTime ?: "Не указана"}"
                                )
                            }
                        }

                        div {
                            className = ClassName("hstack gap-1 flex-wrap")

                            val status = selected.statusInfo()
                            when {
                                status.isDesignAll && status.isMeasureAll && status.isDesignAllTanya && status.isMeasureAllTanya -> {
                                    badge(BootstrapColor.LIGHT, "Заполнено: Все")
                                }

                                status.isDesignAll && status.isMeasureAll -> {
                                    badge(BootstrapColor.LIGHT, "Заполнено: Нет подтверждения Тани")
                                }

                                status.isDesignAll -> {
                                    badge(BootstrapColor.LIGHT, "Не заполнено: Мерки")
                                }

                                status.isMeasureAll -> {
                                    badge(BootstrapColor.LIGHT, "Не заполнено: Дизайн")
                                }

                                else -> {
                                    badge(BootstrapColor.LIGHT, "Не заполнено: Все")
                                }
                            }
                            

                            selected.worksTMP.forEach {
                                val workerCategory = when {
                                    it.user?.group == UserGroup.GLUER -> "Расклейка"
                                    it.user?.group == UserGroup.SEAMSTRESS -> "Пошив"
                                    else -> "Другое"
                                }
                                val workName = when {
                                    it.works?.containsAll(workTypes.filterBy(it.user, selected).toList()) == true -> "Все"
                                    else -> it.prefix()
                                }
                                val readyDate = it.interval?.dateFinish?.let { Date(it).toISOString().split("T").firstOrNull() }


                                badge(BootstrapColor.LIGHT, "$workerCategory: $workName до $readyDate")
    
                            }
                        }
                    }
                }
            ) {

                dropdownRef.current
                    ?.let { Dropdown.getOrCreateInstance(it) }
                    ?.hide()

                GlobalScope.launch {
                    props.client
                        .network
                        .work
                        .addWork(
                            selected,
                            props.user,
                            props.interval,
                            workTypes
                                .filterBy(props.user, selected)
                                .excludeDuplicateDefaults(props.user)
                                .toList(),
                            props.nextWorkId
                        )

                    props.reloadAll()
                }
            }
        }
        fun renderOrderGroup(name: String, filter: (OrderDTO) -> Boolean) {
            val results = props.orders
                .asSequence()
                .filter { it.id !in seenIds }
                .filter { it.name == null || StringUtils.isSimilar(query, it.name ?: "").any }
                .filter(filter)
                .sortedBy { it.name }
                .sortedBy { it.personalData().packageTime }
                .take(remaining)
                .toList()

            if (results.isNotEmpty()) {
                dropdownHeader {
                    badge(BootstrapColor.SUCCESS, name)
                }
            }

            results
                .forEach { selected ->
                    renderOrderItem(selected)
                }

            seenIds.addAll(results.map { it.id })
            remaining -= results.size
        }


        renderOrderGroup("Готовы к работе") { it.allOk && !it.hasAllWorks(workTypes, props.user) }
        renderOrderGroup("Не подтверждены у Тани") { it.allFilled && !it.hasAllWorks(workTypes, props.user) }
        renderOrderGroup("Нет мерок/дизайна") { !it.hasAllWorks(workTypes, props.user) }
        renderOrderGroup("Работа запланирована") { it.hasAllWorks(workTypes, props.user) }
        renderOrderGroup("Остальные") { true }

        dropdownDivider()
        dropdownItem("Показать еще") {
            actualRemaining += 100
        }

    }

}
