@file:Suppress("UNUSED_PARAMETER")

package ru.arty_bikini.crm_frontend.ui.input.table

import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm_frontend.SimpleCache
import ru.arty_bikini.crm_frontend.ui.input.types.*
import kotlin.reflect.KMutableProperty0

class MultiSelectTableColumnBuilder<T : Any> constructor(
    val props: TablePanelProps<T>,
) {

    private fun <V : EntityDTO, P : EntityDTO, C : Collection<P>> create(
        provider: ValueProvider<V>,
        converter: ValueConverter<V, P>,
        collectionAdapter: CollectionAdapter<V, P, C>,

        flag: FieldFlag.Nullable,
        title: String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
        value: T.() -> KMutableProperty0<C?>,
    ) {
        val cache = provider.cache
        if (cache != null) {
            props.cache += cache
        }

        props.columns = props.columns + TablePanelColumnProps<T, V?>(
            cache = provider.cache,
            title = title,
            type = TablePanelColumnType.MULTI_SELECT,
            allowNull = true,
            readOnly = false,
            options = options,
            prop = PropertyReference.readOnlyAnonymous { null },
            getAll = { value().get().let { collectionAdapter.fromCollection(it) }.sortedBy { it.id } },
            setAll = { value().set(collectionAdapter.toCollection(it)) },
            variants = provider.getAll(),
            converter = converter
        )
    }

    operator fun <V : EntityDTO, C : Collection<V>> invoke(
        flag: FieldFlag.Nullable,
        title: String,
        values: SimpleCache<List<V>>,
        adapter: (ValueConverter<V, V>) -> CollectionAdapter<V, V, C>,
        prop: T.() -> KMutableProperty0<C?>,
        name: V.() -> String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {

        val provider = EntityValueProvider(values)
        val converter = EntityValueConverter(provider, name)
        val adapter = adapter(converter)

        create(provider, converter, adapter, flag, title, options, prop)
    }

}