package ru.arty_bikini.crm_frontend.ui.input.table

import csstype.*
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.Json
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.debug.EntityFilter
import ru.arty_bikini.crm_frontend.debug.OrderFilter
import ru.arty_bikini.crm_frontend.network.Entity
import ru.arty_bikini.crm_frontend.network.NetworkModule
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.hint.hint
import ru.arty_bikini.crm_frontend.ui.input.table.data.AutoReloadDataProvider
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSource
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.input.table.data.PagerDataProvider
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*


private val TablePanel = tryFC<TablePanelProps<*>> {

    fun <T : Any> bounded(props: TablePanelProps<T>) {
        var newEntityName by useState<String?> { null }

        val columnsState = ColumnStateHandler(props)

        var cacheSync by useState<Int>(0)

        useEffect(columnsState.rawSort) {
            props.data.setSort(columnsState)
        }

        val filteredDataCache: List<TablePanelEntity<T>> = props
            .data
            .currentData
            .filter(columnsState.filter)

        useEffectOnce {
            val handles = props.cache.map { cache ->
                cache to cache.subscribe { cacheSync++ }
            }

            cleanup {
                handles.forEach { (cache, handlerId) ->
                    cache.unsubscribe(handlerId)
                }
            }
        }

        fun ChildrenBuilder.renderCellHeader(column: TablePanelColumnProps<T, *>) {

            val canSort = props.data.clientSideSorting || column.prop.sortName != null

            val sortInfoIdx = columnsState.rawSort.indexOfFirst { it.id == column.id }
            val sortInfo = columnsState.rawSort.getOrNull(sortInfoIdx)
            val sortText = when {
                sortInfo == null -> "Нет"
                sortInfo.specialName != null -> "${sortInfo.specialName} #${sortInfoIdx + 1}"
                else -> """${sortInfo.direction.displayName} #${sortInfoIdx + 1}"""
            }

            th {

                css {
                    if (columnsState.isVisible(column)) {
                        column.options.minWidthPx?.let {
                            minWidth = it.px
                        }
                        column.options.maxWidthPx?.let {
                            maxWidth = it.px
                        }
                        column.options.widthPercent?.let {
                            width = it.percent
                        }
                    } else {
                        maxWidth = 0.px
                        overflow = Overflow.hidden
                        padding = important(0.px)
                    }
                }

                div {
                    className = ClassName("hstack gap-1")

                    div {
                        className = ClassName("flex-grow-1")
                        +column.title
                    }

                    hint(props.client, "t-" + props.hintId + "-" + (column.prop.sortName ?: column.id))

                    div {
                        css {
                            minWidth = 25.px
                            maxWidth = 25.px
                        }

                        className = className + ClassName("vstack gap-1 justify-content-end flex-grow-0")

                        if (sortInfo != null) {
                            div {
                                className = ClassName("overflow-hidden")

                                badge(BootstrapColor.LIGHT, sortText)

                                title = sortText
                            }
                        }

                        if (column.options.groupNextColumns > 0) {
                            div {
                                + "➕"

                                onClick = { event ->
                                    columnsState.toggleHideAfter(column)

                                    event.stopPropagation()
                                    event.preventDefault()
                                }
                            }
                        }


                        div {

                            if (!canSort) return@div

                            if (sortInfo != null) {
                                className = className + ClassName("bg-info")
                            }

                            dropdown(buttonTitle = {+"⬆️"}, buttonAsDiv = true) {


                                dropdownHeader("Порядок: $sortText")
                                if (!props.data.clientSideSorting) {
                                    dropdownHeader("Rule: " + column.prop.sortName)
                                }

                                dropdownItem("Сортировать") { event ->

                                    columnsState.toggleOrderBy(column)

                                    event.stopPropagation()
                                    event.preventDefault()

                                }

                                if (props.data.clientSideSorting) {
                                    dropdownItem("Добавить в сотрировку") { event ->

                                        columnsState.addOrderBy(column)

                                        event.stopPropagation()
                                        event.preventDefault()
                                    }
                                }
                                dropdownDivider()
                                dropdownItem(SortDirection.ASC.displayName) { event ->

                                    columnsState.setOrderBy(column, SortDirection.ASC)

                                    event.stopPropagation()
                                    event.preventDefault()

                                }

                                dropdownItem(SortDirection.DESC.displayName) { event ->

                                    columnsState.setOrderBy(column, SortDirection.DESC)

                                    event.stopPropagation()
                                    event.preventDefault()

                                }

                                column.options.specialSort
                                    .entries
                                    .sortedBy { it.key }
                                    .forEach {
                                        dropdownItem(it.key) { event ->

                                            columnsState.setSpecialOrderBy(column, it.key, it.value)

                                            event.stopPropagation()
                                            event.preventDefault()
                                        }
                                    }
                            }

                            onClick = { event ->
                                event.stopPropagation()
                                event.preventDefault()
                            }
                        }
                    }

                }

                if (canSort) {
                    onClick = { event ->

                        columnsState.toggleOrderBy(column)

                        event.stopPropagation()
                        event.preventDefault()
                    }
                }

            }
        }
        fun ChildrenBuilder.renderAddHandler(addHandler: suspend (String) -> Unit) {
            tr {
                td {
                    input {
                        className = ClassName("form-control form-control-sm")

                        value = newEntityName ?: ""
                        placeholder = "Введите имя"

                        onChange = {
                            newEntityName = it.target.value
                        }
                    }
                }
                td {

                    val colspan = maxOf(1, props.columns.size - 1)

                    colSpan = colspan

                    button {
                        className = BootstrapButton.btn

                        +"Добавить"

                        onClick = {
                            GlobalScope.launch {
                                newEntityName?.let { addHandler(it) }

//                                    window.location.reload()
                            }

                            newEntityName = null
                        }
                    }
                }
            }
        }

        table {

            className = Bootstrap.tableDefault

            thead {
                css {
                    backgroundColor = important(Color("white"))

//                    top = 50.px
                    height = 60.px
                }
                className = className + ClassName("sticky-top")

                tr {
                    props.columns.forEach { column ->
                        renderCellHeader(column)
                    }
                }
            }
            tbody {

                if (props.data.state != DataSourceLoadingState.LOADED) {
                    tr {
                        td {
                            colSpan = props.columns.size.coerceAtLeast(1)

                            div {
                                className = ClassName("hstack gap-1")
                            }

                            when (props.data.state) {
                                DataSourceLoadingState.LOADING -> badge(BootstrapColor.WARNING, "Идет загрузка данных...")
                                DataSourceLoadingState.LOADED -> badge(BootstrapColor.SUCCESS, "Ок")
                                DataSourceLoadingState.ERROR -> badge(BootstrapColor.DANGER, "Ошибка загрузки данных. Попробуйте обновить страницу")
                            }
                        }
                    }
                }

                filteredDataCache.forEach { (item, new, updated, removed) ->

                    tr {
                        
                        className = className + ClassName("position-relative bg-opacity-25") + listOfNotNull(
                            if (new) ClassName("bg-" + BootstrapColor.SUCCESS.suffix) else null,
                            if (updated) ClassName("bg-" + BootstrapColor.WARNING.suffix) else null,
                            if (removed) ClassName("bg-" + BootstrapColor.DANGER.suffix) else null,
                        ).reduceOrNull { a, b -> a + b }

                        props.columns.forEachIndexed { idx, column ->

                            td {
    
                                css {
                                    if (idx == 0) {
                                        position = Position.sticky
                                        left = 0.px

                                        backgroundColor = important(Color("white"))
                                    }

                                    if (columnsState.isVisible(column)) {
                                        column.options.minWidthPx?.let {
                                            minWidth = it.px
                                        }
                                        column.options.maxWidthPx?.let {
                                            maxWidth = it.px
                                        }
                                        column.options.widthPercent?.let {
                                            width = it.percent
                                        }
                                    } else {
                                        maxWidth = 0.px
                                        overflow = Overflow.hidden
                                        padding = important(0.px)
                                    }
                                }

                                className = className + ClassName("pe-0")

                                TablePanelItem {
                                    val attrs = this as TablePanelItemProps<T, Any?>

                                    attrs.client = props.client

                                    attrs.item = item
                                    attrs.column = column as TablePanelColumnProps<T, Any?>

                                    attrs.itemId = props.getId(item)

                                    attrs.onUpdate = { force ->
                                        GlobalScope.launch {
                                            props.save(item)
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                val addHandler = props.addNew
                if (addHandler != null) {
                    renderAddHandler(addHandler)
                }

            }
        }

    }
    bounded(it.unsafeCast<TablePanelProps<Any>>())

}

private fun <T : Any> ChildrenBuilder.TablePanel(
    client: ClientCore,
    hintId: String,
    data: DataSource<T>,
    getId: (T) -> String,
    init: TablePanelProps<T>.() -> Unit
) {
    TablePanel.invoke {

        val props = this.unsafeCast<TablePanelProps<T>>()
        props.client = client

        props.hintId = hintId

        props.getId = getId

        props.data = data
        props.save = { }
        props.addNew = null
        props.columns = emptyList()
        props.cache = emptyList()

        init(this as TablePanelProps<T>)
    }
}

fun <T : Entity> ChildrenBuilder.TablePanelEntityAuto(
    client: ClientCore,
    hintId: String,
    data: List<T>,
    serializer: KSerializer<T>,
    init: TablePanelProps<T>.() -> Unit
) {

    client.event.refreshData(serializer.descriptor.serialName, data)

    AutoReloadDataProvider(
        client = client,
        sync = data,
        getId = { it.id },
        getHash = { Json.encodeToString(serializer, it) },
    ) { source ->
        TablePanel<T>(
            client = client,
            hintId = hintId,
            data = source,
            getId = { it.id.toString() },
            init = init
        )
    }
}
fun <T : Entity, F : EntityFilter> ChildrenBuilder.TablePanelEntityPage(
    client: ClientCore,
    hintId: String,
    data: suspend NetworkModule.(F) -> PageDTO<T>?,
    defaultFilter: () -> F,
    init: TablePanelProps<T>.() -> Unit
) {

    PagerDataProvider(
        client = client,
        loadPage = data,
        defaultFilter = defaultFilter,
    ) { source ->
        TablePanel<T>(
            client = client,
            hintId = hintId,
            data = source,
            getId = { it.id.toString() },
            init = init
        )
    }
}

fun ChildrenBuilder.TablePanelOrderSource(
    client: ClientCore,
    source: DataSource<OrderDTO>,
    hintId: String,
    init: TablePanelProps<OrderDTO>.() -> Unit
) {

    TablePanel<OrderDTO>(
        client = client,
        hintId = hintId,
        data = source,
        getId = { it.id.toString() },
    ) {

        this.save = {
            client
                .network
                .order
                .saveOrder(it)
        }

        init(this)

    }

}

fun ChildrenBuilder.TablePanelOrderAuto(
    client: ClientCore,
    hintId: String,
    data: List<OrderDTO>,
    init: TablePanelProps<OrderDTO>.() -> Unit
) {

    AutoReloadDataProvider(
        client = client,
        sync = data,
        getId = { it.id },
        getHash = { it.version.toString() },
    ) { source ->
        TablePanel<OrderDTO>(
            client = client,
            hintId = hintId,
            data = source,
            getId = { it.id.toString() },
        ) {

            this.save = {
                client
                    .network
                    .order
                    .saveOrder(it)
            }

            init(this)

        }
    }
}

fun ChildrenBuilder.TablePanelOrderPage(
    client: ClientCore,
    hintId: String,
    data: suspend NetworkModule.(OrderFilter) -> PageDTO<OrderDTO>?,
    defaultFiler: OrderFilter,
    init: TablePanelProps<OrderDTO>.() -> Unit
) {
    PagerDataProvider(
        client = client,
        loadPage = data,
        defaultFilter = { defaultFiler.clone(OrderFilter.serializer()) }
    ) { source ->
        TablePanel<OrderDTO>(
            client = client,
            hintId = hintId,
            data = source,
            getId = { it.id.toString() },
        ) {

            this.save = {
                client
                    .network
                    .order
                    .saveOrder(it)
            }

            init(this)

        }
    }
}

