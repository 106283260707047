package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm_frontend.ClientCore

class NetworkModule(val clientCore: ClientCore) {

    val auth = AuthService(clientCore)
    val order = OrderService(clientCore)
    val orderData = OrderDataService(clientCore)
    val work = WorkService(clientCore)
    val dict = DictService(clientCore)
    val stone = OrderStoneService(clientCore)
    val file = FileService(clientCore)

}
