package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.form.button.ButtonArchiveForm
import ru.arty_bikini.crm_frontend.form.button.ButtonPrePaymentForm
import ru.arty_bikini.crm_frontend.form.calc.AdditionalItemsInput
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.form.addSpecial
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.clone
import ru.arty_bikini.crm_frontend.util.personalData
import ru.arty_bikini.crm_frontend.util.useCache
import kotlin.math.max
import kotlin.math.min

val PersonalDataFormPart = tryFC<FormPartProps> { props ->

    val trainers = useCache(props.client.cache.trainers)
    val productTypes = useCache(props.client.cache.productTypes)
    val expressTypes = useCache(props.client.cache.expressTypes)

    FormInput(props.client, props.order) {

        readOnly = props.readOnly

        onSave {
            props
                .client
                .network
                .order
                .saveOrder(it)
        }

        content { entity ->

            cardGreenCollapse("Персональные данные", ClassName("vstack gap-2")) {

                row {
                    colSm(12) {

                        inputGroup {
                            name("Фамилия Имя")

                            addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.FIO) //данные из гугола
                            addText(FieldFlag.Nullable) { this::name } //текст редактирование
                        }
                    }
                }
                row {
                    colSm(6) {
                        inputGroup {
                            name("Статус")
                            addText(FieldFlag.ReadOnlyProp) { this.type.displayName }

                            if (props.readOnly) return@inputGroup

                            if (entity.type == ClientType.LEAD) {
                                btn(BootstrapColor.PRIMARY, "Предоплата") {
                                    props.client
                                        .event
                                        .showModal(ButtonPrePaymentForm, props.order)
                                }
                            }
                            btn(BootstrapColor.SECONDARY, "В архива") {
                                props.client
                                    .event
                                    .showModal(ButtonArchiveForm, props.order)
                            }
                        }
                    }
                    colSm(6) {
                        inputGroup {
                            name("Тип заказа")
                            addSelect(FieldFlag.Nullable, productTypes, {it.name}) { this::product }
                        }
                    }
                }
                row {
                    colSm(6) {
                        inputGroup {
                            name("Телефон")
                            addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.PHONE)
                            addText(FieldFlag.Nullable) { this.personalData()::telephon }
                        }
                    }
                    colSm(6) {
                        inputGroup {
                            name("Город")
                            addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CITY)
                            addText(FieldFlag.Nullable) { this.personalData()::city }
                        }
                    }
                }

                row {
                    colSm(4) {
                        inputGroup {
                            name("Предоплата")
                            addText(FieldFlag.Nullable) { this.personalData()::prepayment }
                        }
                    }
                    colSm(4) {
                        inputGroup {
                            name("Цена")
                            addCost(FieldFlag.NotNull) { this.personalData()::payment }
                        }
                    }
                    colSm(4) {
                        inputGroup {
                            name("Тренер")
                            addSelect(FieldFlag.Nullable, trainers, { it.name }) { this.personalData()::trainer }
                        }
                    }
                }

                val initialCost = entity.personalData().payment

                val trainer = entity.personalData().trainer
                val discountCount = trainer?.discountCount ?: 0
                val discountPercent = initialCost * (trainer?.discountPercent ?: 0) / 100
                val discount: Int = when {
                    discountCount < 1 -> discountPercent
                    discountPercent < 1 -> discountCount
                    else -> min(discountCount, discountPercent)
                }.coerceIn(0, initialCost)

                if (discount > 0) {
                    val discountPercentReal = 100 * discount / (initialCost + discount)

                    row {
                        colSm(4) {
                        }
                        colSm(4) {
                            badge(
                                color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                text = "Общая сумма заказа: ${StringUtils.printNumber(initialCost + discount)}",
                                extraClass = ClassName("float-end"),
                            )
                        }
                        colSm(4) {
                            badge(
                                color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                text = "Скидка ($discountPercentReal%): ${StringUtils.printNumber(discount)}",
                                extraClass = ClassName("float-end"),
                            )
                        }
                    }
                }


                hr { className = ClassName("expand-3") }



                row {

                    colSm(3) {
                        inputColumn {
                            name("Дата соревнования")
                            addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.COMPETITION_GOOGLE)
                        }
                    }
                    colSm(3) {
                        inputColumn {
                            name("Дата когда нужен")
                            addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.NEEDED_GOOGLE)
                        }
                    }
                    colSm(3) {
                        inputColumn {
                            name("Дата мерок")
                            addDate(FieldFlag.ReadOnlyProp) { this.dataGoogle?.dateGoogle }
                        }
                    }
                    colSm(3) {
                        inputColumn {
                            name("Дата предоплаты")
                            addDate(FieldFlag.ReadOnlyProp) { this.personalData().orderTime }
                        }
                    }

                }

                row {
                    colSm(6) {
                        inputGroup(ClassName("mb-2")) {
                            name("Срок доставки")
                            addInt(FieldFlag.NotNull) { this.personalData()::deliveryTime }
                            name("дней")
                        }
                        inputGroup {
                            name("Срочность")
                            addSelect(FieldFlag.Nullable, expressTypes, { "${it.minDays} - ${it.maxDays} дней" }) { this::express }
                        }
                    }
                    colSm(3) {
                        inputColumn {
                            name("Дата окончания производства")
                            cb.inputGroup {
                                addDate(FieldFlag.ReadOnlyProp) { this.personalData().packageManufacture }

                                val packageTime = entity.personalData().packageTime
                                when {
                                    entity.personalData().packageManufacture == null || entity.personalData().packageClient == null -> warn(BootstrapColor.WARNING, "Нет данных")
                                    (entity.personalData().packageManufacture ?: 0) < (entity.personalData().packageClient ?: 0) -> warn(BootstrapColor.SUCCESS, "Ок")
                                    packageTime != null -> warn(BootstrapColor.SECONDARY, "Заменено")
                                    else -> warn(BootstrapColor.DANGER, "Не успеваем!")
                                }
                            }
                        }
                    }
                    colSm(3) {
                        inputColumn {
                            name("Дата когда нужен (минус доставка)")
                            addDate(FieldFlag.ReadOnlyProp) { this.personalData().packageClient }
                        }
                    }
                }

                row {
                    colSm(12) {
                        inputColumn {
                            name("Дата отправки")
                        }
                    }
                }

                row {

                    colSm(6) {
                        inputGroup {
                            name("Заменить")
                            addDate(FieldFlag.Nullable) { this.personalData()::packageManager }
                            cb.apply {
                                if (!props.readOnly) {
                                    button {
                                        className = BootstrapButton.outline(BootstrapColor.SECONDARY)

                                        +"Подтвердить"

                                        onClick = {
                                            GlobalScope.launch {
                                                val entity = entity.clone(OrderDTO.serializer())
                                                entity.personalData().packageNow = true

                                                props.client
                                                    .network
                                                    .order
                                                    .saveOrder(entity)
                                            }
                                        }
                                    }
                                }
                                dropdown("Инфо") {
                                    entity.personalData().userPackage?.split("|")?.forEach {
                                        dropdownItem(it) { }
                                    }
                                }
                            }
                        }
                    }

                    colSm(3) {}

                    colSm(3) {
                        div {
                            className = ClassName("vstack gap-1")

                            inputGroup {
                                name("Итоговая")
                                addDate(FieldFlag.ReadOnlyProp) { this.personalData().packageTime }
                            }

                            val startDate = max(entity.personalData().orderTime ?: 0, entity.dataGoogle?.dateGoogle ?: 0)
                            val msPerDay = 24 * 60 * 60 * 1000L

                            val packageTime = entity.personalData().packageTime
                            val express = entity.express

                            when {
                                packageTime == null -> when {
                                    entity.personalData().packageManufacture == null || entity.personalData().packageClient == null -> badge(BootstrapColor.WARNING, "Нет данных")
                                    else -> badge(BootstrapColor.DANGER, "Не заполнена дата отправки")
                                }
                                (packageTime) > (entity.personalData().packageClient ?: 0) -> badge(BootstrapColor.DANGER, "Не успеет к дате когда нужен")
                                express == null && startDate + msPerDay * 4 * 7 > packageTime -> badge(BootstrapColor.DANGER, "Не успеет за 4 недели. Задайте срочность.")
                                express != null && startDate + msPerDay * express.minDays > packageTime -> badge(BootstrapColor.DANGER, "Не успеет за ${express.minDays} дней. Измените срочность.")

                                else -> badge(BootstrapColor.SUCCESS, "Заполнено")
                            }
                        }
                    }
                }

                hr { className = ClassName("expand-3") }

                inputColumn {
                    name("Комментарии")
                    addTextArea(3) { this.personalData()::comment }
                }

                inputColumn {
                    name("Допы")
                }

                AdditionalItemsInput(props.client, entity, props.readOnly, showPrices = false, columns = 3)

                inputColumn {
                    name("Допы (комментарий)")
                    addText(FieldFlag.Nullable) { this.personalData()::addOrder }
                }



            }

        }
    }
}