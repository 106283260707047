package ru.arty_bikini.crm_frontend.table.production

import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownDivider
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownHeader
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.filterBy
import ru.arty_bikini.crm_frontend.util.prefix
import ru.arty_bikini.crm_frontend.util.useCache
import kotlin.js.Date

external interface OrderWorkListProps : ClientProps {
    var order: OrderDTO
}

val OrderWorkList = tryFC<OrderWorkListProps> { props ->

    val workTypes = useCache(props.client.cache.workTypes)
    val workTypesGluer = workTypes.filterBy(UserGroup.GLUER, props.order).toList()
    val workTypesSeamstress = workTypes.filterBy(UserGroup.SEAMSTRESS, props.order).toList()

    dropdownHeader("Швеи:")

    props.order.worksTMP
        ?.asSequence()
        ?.filter { it.user?.group == UserGroup.SEAMSTRESS }
        ?.sortedBy { it.interval?.dateFinish }
        ?.forEach { work ->
            val date = work.interval?.dateFinish?.let { Date(it) }?.toISOString()?.takeWhile { it != 'T' }
            val date2 = work.interval?.dateStart?.let { Date(it) }?.toISOString()?.takeWhile { it != 'T' }
            val works: String?
            if (work.works?.containsAll(workTypesSeamstress) == true) {
                works = "Все"
            } else {
                works = work.prefix()
            }

            dropdownHeader(work.user?.login + "   "+ works + "    от: " + date2 + "  до: " + date)
        }

    dropdownDivider()
    dropdownHeader("Расклейка:")

    props.order.worksTMP
        ?.asSequence()
        ?.filter { it.user?.group == UserGroup.GLUER }
        ?.sortedBy { it.interval?.dateFinish }
        ?.forEach { work ->

            val dateFinish = work.interval?.dateFinish?.let { Date(it) }?.toISOString()?.takeWhile { it != 'T' }
            val dateStart = work.interval?.dateStart?.let { Date(it) }?.toISOString()?.takeWhile { it != 'T' }
            val works: String?
            if (work.works?.containsAll(workTypesGluer) == true) {
                works = "Все"
            } else {
                works = work.prefix()
            }

            dropdownHeader(work.user?.login + "   "+ works + "    от: " + dateStart + "  до: " + dateFinish)

        }

}