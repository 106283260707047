package ru.arty_bikini.crm_frontend.table.report

import csstype.ClassName
import react.dom.html.ReactHTML
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.table.production.worksTMP
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardCollapse
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntity
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface OrderReportProps : ClientProps {
    var data: List<TablePanelEntity<OrderDTO>>
}

val ConversionReport = tryFC<OrderReportProps> { props ->

    val total = props.data.size
    val withPrepay = props.data.count { it.entity.personalData?.prepayment != null }
    val withAllWorksDone = props.data.count { it.entity.worksTMP.isNotEmpty() && it.entity.worksTMP.all { it.approved } }
    val incomplete = props.data.count { it.entity.type != ClientType.ARCHIVE }

    cardCollapse("Конверсия",  ClassName("vstack gap-1"), alertColor = BootstrapColor.INFO) {

        if (incomplete == 0) {
            badge(BootstrapColor.SUCCESS, "Отчет готов")
        } else {
            badge(BootstrapColor.WARNING, "Не вызрело $incomplete заявок (${100 * incomplete / total}%)")
        }

        inputGroup {
            name("Всего заявок")
            value(total.toString())
        }
        inputGroup {
            name("С предоплатой")
            value(withPrepay.toString())
            name("${100 * withPrepay / total}%")
        }
        inputGroup {
            name("Все работы завершены")
            value(withAllWorksDone.toString())
            name("${100 * withAllWorksDone / total}%")
        }
        inputGroup {
            name("Заказов выполнено")
            value("???")
            name("??%")
        }

    }
}