package ru.arty_bikini.crm_frontend.ui.hint

import react.ChildrenBuilder
import react.dom.html.ReactHTML.img
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.debug.HintDTOClient
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdown
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownDivider
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.md.MarkdownComponent
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface HintComponentProps : ClientProps {
    var hintKey: String
}

private val HintComponent = tryFC<HintComponentProps> { props ->

    val hints = props.client.settings.hintsCache

    val hint = hints.find { it.key == props.hintKey }

    if (props.client.settings.showDisabledHints == false && hint == null) {
        return@tryFC
    }

    dropdown(
        buttonTitle = {
            badge(BootstrapColor.INFO, "?")
        },
        buttonAsDiv = true,
        menu = {
            if (hint?.file != null) {
                img {
                    src = hint.file?.location
                }
                dropdownDivider()
            }
            MarkdownComponent(hint?.content ?: "__Не заполнено__")
            if (props.client.auth.currentUser?.group == UserGroup.ADMIN) {
                dropdownDivider()
                InputPanel {
                    val content = addText("Содержимое")

                    onSave {
                        val content = get(content) ?: return@onSave

                        val mutable = hints.toMutableList()
                        mutable.removeAll { it.key == props.hintKey }

                        mutable.add(HintDTOClient(-1, props.hintKey, null, content))

                        props.client.settings.hintsCache = mutable
                    }

                }
            }
        }
    )
}

fun ChildrenBuilder.hint(client: ClientCore, hintKey: String) {
    HintComponent {
        this.client = client
        this.hintKey = hintKey
    }
}