package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.packet.auth.*
import ru.arty_bikini.crm_frontend.ClientCore


class AuthService(clientCore: ClientCore) : BaseService(clientCore) {


    suspend fun reconnect(): ReconnectResponse? {
        return call<ReconnectResponse>("/api/auth/reconnect").body
    }

    suspend fun login(login: String, password: String): LoginResponse? {
        return call<LoginResponse>(
            url = "/api/auth/login",
            query = mapOf(
                "login" to login,
                "password" to password
            )
        ).body
    }

    suspend fun getUsers(): GetUsersResponse? {
        return call<GetUsersResponse>("/api/auth/get-users").body
    }

    suspend fun changePassword(user: UserDTO): ChangePasswordResponse? {
        return call<ChangePasswordResponse>(
            url = "/api/auth/change-password",
            query = mapOf(
                "id" to user.id.toString()
            )
        ).body
    }

    suspend fun editUser(user: UserDTO): EditUserResponse? {
        return call<EditUserResponse, EditUserRequest>(
            url = "/api/auth/edit-user",
            query = emptyMap(),
            body = EditUserRequest(user)
        ).body
    }

}
