package ru.arty_bikini.crm_frontend.table.production.worker

import csstype.ClassName
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm_frontend.form.main.WorkerMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.table.production.WorkCell
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alertDanger
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.prefix
import ru.arty_bikini.crm_frontend.util.useAutoReload
import kotlin.js.Date

val OwnWorksTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.SEAMSTRESS, UserGroup.GLUER)
) { props ->

    val user = props.client.auth.currentUser

    val works = useAutoReload {
        props.client
            .network
            .work
            .getIntervalWork(Date(Date.now()))
            ?.workDTOList
            ?.filter { it.user == user }
    }

    if (user == null || user.group !in listOf(UserGroup.SEAMSTRESS, UserGroup.GLUER)) {
        alertDanger {
            + "У данного пользователя не бывает работ"
        }

        return@commonPage
    }

    TablePanelEntityAuto(props.client, "t-own-work", works, WorkDTO.serializer()) {

        addDate(FieldFlag.ReadOnlyProp, "Срок", WorkDTO::interval or ::IntervalDTO then IntervalDTO::dateFinish)
        addInt(FieldFlag.ReadOnlyProp, "Приоритет", WorkDTO::priority)
        addText(FieldFlag.ReadOnlyProp, "ФИО", WorkDTO::order or ::OrderDTO then OrderDTO::name)
        addText(
            flag = FieldFlag.ReadOnlyValue,
            title = "Работы",
            options = TableColumnRenderOptions(
                extraContent = { entity ->
                    div {
                        className = ClassName("vstack gap-1")

                        entity.works?.forEach {
                            badge(BootstrapColor.INFO, it.name ?: "<null>")
                        }
                    }
                }
            ),
            value = { "" }
        )
        addButton(
            title = "Статус",
            options = TableColumnRenderOptions(
                extraContent = { entity ->
                    WorkCell {
                        this.client = props.client
                        this.work = entity
                        this.reloadAll = {}
                        this.changePriority = {}
                    }
                }
            ),
            text = { "" },
            onClick = { /* no-op */ }
        )
        addButton("Мерки") { it.order?.let { WorkerMainForm.openAction(props.client)(it) } }
        addDate(FieldFlag.ReadOnlyProp, "Дата отправки/примерки", WorkDTO::order or ::OrderDTO then OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::packageTime)

    }

}