package ru.arty_bikini.crm_frontend.debug

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.dict.TrainerDTO
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.packet.BaseResponse
import ru.arty_bikini.crm_frontend.ui.input.table.SortDirection

@Serializable
class GetOrdersPageRequest (
    var filter: OrderFilter
)

@Serializable
class GetOrdersPageResponse(
    override val statusCode: String,
    override val ok: Boolean,
    override val displayMessage: String?,

    var data: PageDTO<OrderDTO>?,
) : BaseResponse()


@Serializable
class OrderFilter : EntityFilter() {

    var type: ClientType? = null
    var trainer: TrainerDTO? = null

    var createdTimeFrom: Long? = null
    var createdTimeTo: Long? = null

}

@Serializable
open class EntityFilter {

    var page: Int = 0

    var orderColumn: String? = null
    var orderDirection: SortDirection = SortDirection.ASC

}