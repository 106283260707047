package ru.arty_bikini.crm_frontend.table.report

import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.debug.OrderFilter
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.table.production.worksTMP
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alert
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.input.table.data.month.MonthPagerDataProviderOrder
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.personalData

val MoneyTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->


    MonthPagerDataProviderOrder(
        client = props.client,
        defaultFilter = ::OrderFilter
    ) { data ->

        cardLightShadow {
            if (data.state != DataSourceLoadingState.LOADED) {
                alert(BootstrapColor.WARNING) { +"Идет загрузка данных" }
            } else {
                alert(BootstrapColor.SUCCESS) {}
            }

            if (data.state == DataSourceLoadingState.LOADED) {

                MoneyReport {
                    this.client = props.client
                    this.data = data.currentData
                }

            }

        }

        cardLightShadow {
            TablePanelOrderSource(props.client, data, "t-r-money") {
                addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name)
                addText(FieldFlag.ReadOnlyProp, "Предоплата", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment)
                addInt(FieldFlag.ReadOnlyProp, "Сумма заказа", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment)
                addInt(FieldFlag.ReadOnlyValue, "Скидка клиенту (вверх)") { trainerDiscount }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение тренеру (вниз)") { trainerPay }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение сотрудникам за работу") { workMain }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение сотрудникам за допы") { workExtra }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение сотрудникам за расклейку") { workStone }
                addInt(FieldFlag.ReadOnlyValue, "Цена - Зарплаты") { personalData().payment - trainerPay - workMain - workExtra - workStone }
            }
        }
    }
}

val OrderDTO.trainerDiscount: Int get() = min(
    personalData().payment,
    personalData().trainer?.discountCount ?: 0,
    personalData().trainer?.discountPercent ?: 0
)

val OrderDTO.trainerPay: Int get() =
    min(
        personalData().payment,
        personalData().trainer?.payCount ?: 0,
        personalData().trainer?.payPercent ?: 0
    )

val OrderDTO.workMain: Int get() = worksTMP.sumOf { it.works?.sumOf { it.paySeamstress } ?: 0 }
val OrderDTO.workExtra: Int get() = worksTMP.sumOf { it.works?.sumOf { it.paySeamstress } ?: 0 }
val OrderDTO.workStone: Int get() = stones?.sumOf { it.rhinestoneType?.payGluerCount ?: 0 } ?: 0