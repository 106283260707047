package ru.arty_bikini.crm_frontend.ui.input.table

import ru.arty_bikini.crm_frontend.SimpleCache
import ru.arty_bikini.crm_frontend.ui.input.types.PropertyReference
import ru.arty_bikini.crm_frontend.ui.input.types.ValueConverter

class TablePanelColumnProps<T : Any, V> constructor(
    val cache: SimpleCache<*>? = null,
    val title: String,
    val type: TablePanelColumnType,
    val allowNull: Boolean,
    val readOnly: Boolean,
    val options: TableColumnRenderOptions<T>,
    val prop: PropertyReference<T, V>,
    val getAll: T.() -> List<V & Any> = { listOf(prop.get(this)).filterNotNull() },
    val setAll: T.(List<V & Any>) -> Unit = { it.firstOrNull()?.let { prop.set(this, it) } },
    val variants: List<V & Any>?,
    val converter: ValueConverter<V & Any, *>,
) {

    val id = title

    fun asString(it: V): String = converter.toText(it)
    fun asSort(it: V): String = it?.let { converter.toSort(it) } ?: "<null>"
    fun asKey(it: V?): String = it?.let { converter.toKey(it) } ?: "<null>"
    fun fromInput(it: String): V? = converter.fromKey(it)

    fun get(entity: T): V = prop.get(entity)
    fun set(entity: T, value: V): Unit = prop.set(entity, value)

    fun getAsString(value: T): String {
        return asString(get(value))
    }

    fun getAsSort(value: T): String {
        return asSort(get(value))
    }
}

enum class TablePanelColumnType {
    BUTTON,
    BUTTON_DROPDOWN,
    TEXT_LINE,
    TEXT_AREA,
    INT,
    CHECKBOX,
    DATE,
    DATE_TIME,
    SELECT,
    MULTI_SELECT,
}

