package ru.arty_bikini.crm_frontend.table.report

import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.debug.OrderFilter
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelOrderSource
import ru.arty_bikini.crm_frontend.ui.input.table.addText
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.input.table.data.month.MonthPagerDataProviderOrder

val ConversionTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->


    MonthPagerDataProviderOrder(
        client = props.client,
        defaultFilter = ::OrderFilter
    ) { data ->

        cardLightShadow {
            if (data.state != DataSourceLoadingState.LOADED) {
                alert(BootstrapColor.WARNING) { +"Идет загрузка данных" }
            }

            if (data.state == DataSourceLoadingState.LOADED) {

                ConversionReport {
                    this.client = props.client
                    this.data = data.currentData
                }

            }

        }

        cardLightShadow {
            TablePanelOrderSource(props.client, data, "t-r-conversion") {
                addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name)
            }
        }
    }
}