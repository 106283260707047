package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.TrainerDTO
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.ui.input.table.*

val TrainerTablePage = dictTablePage(
    serializer = TrainerDTO.serializer(),
    load = { dict.getTrainers()?.trainerList },
    save = { dict.editTrainer(it) },
    addNew = { dict.addTrainer(TrainerDTO().apply { this.name = it }) },
) {

    addText(FieldFlag.Nullable, "ФИО", TrainerDTO::name)
    addInt(FieldFlag.NotNull, "Сортировка", TrainerDTO::priority, options = TableColumnRenderOptions(defaultSort = true))
    addBoolean(FieldFlag.NotNull, "Показать", TrainerDTO::visible)

    addInt(FieldFlag.NotNull, "Вознаграждение (руб)", TrainerDTO::payCount)
    addInt(FieldFlag.NotNull, "Вознаграждение (процент)", TrainerDTO::payPercent)
    addInt(FieldFlag.NotNull, "Скидка (руб)", TrainerDTO::discountCount)
    addInt(FieldFlag.NotNull, "Скидка (процент)", TrainerDTO::discountPercent)

}
