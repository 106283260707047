package ru.arty_bikini.crm_frontend.form.button

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.leadInfo
import ru.arty_bikini.crm_frontend.util.personalData

val ButtonArchiveForm = createModal<OrderDTO>(title = { "Отправить в архив" }) { props ->
    FormInput(props.client, props.entity) {
        inputGroup {
            name("ФИО")
            addText(FieldFlag.ReadOnlyProp) { this.name }
        }
        inputGroup {
            name("Телефон")
            addText(FieldFlag.ReadOnlyProp) { this.personalData().telephon }
        }
        inputGroup {
            name("Предоплата")
            addText(FieldFlag.ReadOnlyProp) { this.personalData().prepayment }
        }
        inputGroup {
            name("Сумма заказа")
            addInt(FieldFlag.ReadOnlyProp) { this.personalData().payment }
        }
    }


    InputPanel {

        val reason = addText("Причина: ")

        onSave("Отправить в архив") {
            val value = get(reason)

            if (value.isNullOrBlank()) {
                return@onSave
            }

            val entity = props.entity

            entity.leadInfo().commentArchive = "Статус: " + entity.type.displayName + " | Причина: " + value
            entity.type = ClientType.ARCHIVE

            GlobalScope.launch {

                props.client
                    .network
                    .order
                    .saveOrder(entity)

                props.client
                    .event
                    .closeModal()
            }
        }

        onCancel("Отмена") {
            props.client
                .event
                .closeModal()
        }
    }
}