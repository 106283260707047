package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import csstype.Overflow
import csstype.px
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDivElement
import react.createRef
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useState
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.WorkProgress
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.form.main.WorkerMainForm
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.InlineInput
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*
import kotlin.js.Date

external interface WorkCellProps : ClientProps {
    var work: WorkDTO

    var reloadAll: () -> Unit

    var changePriority: (Int) -> Unit
}

val WorkCell = tryFC<WorkCellProps> { props ->

    val workTypes = useCache(props.client.cache.workTypes)
    val workTypesForCurrent = workTypes.filterBy(props.work.user, props.work.order ?: OrderDTO()).toList()

    var order by useState(props.work.order)

    useEffect(props.work.order?.id) {

        val orderTmp = props.work.order
        if (orderTmp != null) {

            GlobalScope.launch {
                props.client
                    .network
                    .order
                    .getOrder(orderTmp)
                    ?.orderDTO
                    ?.let { order = it }
            }

        }

    }

    val canEditWork = props.client.auth.currentUser?.group?.canEditWork == true

    val duplicateWorks = props.work
        .order
        ?.worksTMP
        ?.filter { it.id != props.work.id }
        ?.filter { it.works?.any { props.work.works?.contains(it) == true } == true }
        ?: emptyList()

    val lateWorks = props.work
        .order
        ?.worksTMP
        ?.filter { it.id != props.work.id }
        ?.filter { when (props.work.user?.group) {
            null -> false
            UserGroup.SEAMSTRESS -> it.user?.group == UserGroup.GLUER && (it.interval?.dateStart ?: 0) < (props.work.interval?.dateFinish ?: 0)
            UserGroup.GLUER -> it.user?.group == UserGroup.SEAMSTRESS && (it.interval?.dateFinish ?: 0) > (props.work.interval?.dateStart ?: 0)
            else -> false
        } }
        ?: emptyList()


    val dropdownRef = createRef<HTMLDivElement>()

    dropdown(
        ref = dropdownRef,
        buttonTitle = {
            div {
                className = ClassName("card vstack gap-1 p-1 border-0")

                if (duplicateWorks.isNotEmpty()) {
                    className = className + ClassName("bg-${BootstrapColor.DANGER.suffix}")
                } else if (lateWorks.isNotEmpty()) {
                    className = className + ClassName("bg-${BootstrapColor.WARNING.suffix}")
                } else {
                    className = className + ClassName("bg-transparent")

                }
    
                badge(
                    color = BootstrapColor.LIGHT,
                    text = order?.name.toString(),
                    extraCss = {
                        this.maxWidth = 150.px
                        overflow = Overflow.hidden
                    }
                )

                div {
                    className = ClassName("hstack gap-1")

                    if (props.work.works.isNullOrEmpty()) {
                        badge(BootstrapColor.DANGER, "Нет", extraClass = ClassName("flex-grow-1"))
                    } else if (workTypesForCurrent.all { props.work.works?.contains(it) == true }) {
                        badge(BootstrapColor.LIGHT, "Всё", extraClass = ClassName("flex-grow-1"))
                    } else {
                        badge(BootstrapColor.INFO, props.work.prefix(), extraClass = ClassName("flex-grow-1"))
                    }

                    when {
                        props.work.approved -> badge(BootstrapColor.PRIMARY, "Принята")
                        props.work.progress == WorkProgress.FINISHED -> badge(BootstrapColor.SUCCESS, "Готова")
                        props.work.progress != null -> badge(BootstrapColor.WARNING, props.work.progress?.displayName ?: "Работа")
                        else -> badge(BootstrapColor.LIGHT, "Ожидает")
                    }
                }
            }
        },
        buttonAsDiv = true,
        extraClasses = ClassName("w-100"),
        menuExtraClasses = ClassName("p-0"),
        menu = {
            div {
                css {
                    minWidth = 350.px
                }
                className = className + ClassName("card pb-2")


                ReactHTML.div {
                    className = ClassName("card-header")

                    +order?.name.orEmpty()
                }

                if (duplicateWorks.isNotEmpty() || lateWorks.isNotEmpty()) {
                    div {
                        className = ClassName("vstack p-2 gap-1 pb-0")

                        duplicateWorks.forEach {
                            badge(BootstrapColor.DANGER, "Повтор: ${it.user?.login}")
                        }

                        val prefix = if (props.work.user?.group == UserGroup.SEAMSTRESS) {
                            "Назначено раньше"
                        } else {
                            "Назначено позже"
                        }
                        lateWorks.forEach {
                            badge(BootstrapColor.WARNING, "$prefix: ${it.user?.login}")
                        }
                    }
                    dropdownDivider()
                }

                if (canEditWork) {
                    div {
                        className = ClassName("hstack gap-1")

                        div {
                            className = ClassName("flex-grow-1")

                            dropdownHeader("Работы:")
                            workTypesForCurrent.forEach {
                                ReactHTML.a {
                                    className = ClassName("dropdown-item")

                                    +it.name.orEmpty()

                                    if (props.work.works?.contains(it) == true) {
                                        badge(BootstrapColor.SUCCESS, "Назначена", extraClass = ClassName("float-end"))
                                    } else {
                                        badge(BootstrapColor.DANGER, "Нет", extraClass = ClassName("float-end"))
                                    }

                                    onClick = { event ->
                                        val works = props.work.works?.toMutableList() ?: ArrayList()
                                        if (works.contains(it)) {
                                            works.remove(it)
                                        } else {
                                            works.add(it)
                                        }

                                        GlobalScope.launch {
                                            val new = props.work.clone(WorkDTO.serializer())

                                            new.works = ArrayList(works)

                                            props.client
                                                .network
                                                .work
                                                .editWork(new)

                                            props.reloadAll()
                                        }
                                    }
                                }
                            }
                        }
                        div {
                            className = ClassName("vstack gap-2 flex-grow-0 pe-2")

                            inputGroup {
                                btn(BootstrapColor.SECONDARY, "^") {
                                    props.changePriority(-1)

                                    dropdownRef.current
                                        ?.let { Dropdown.getOrCreateInstance(it) }
                                        ?.hide()
                                }
                                btn(BootstrapColor.PRIMARY, "^^") {
                                    props.changePriority(-100)

                                    dropdownRef.current
                                        ?.let { Dropdown.getOrCreateInstance(it) }
                                        ?.hide()
                                }
                            }
                            InlineInput {
                                css = {
                                    it.width = 50.px
                                }
                                value = props.work.priority.toString()
                                onBlur = event@{

                                    val value = it.toIntOrNull() ?: return@event

                                    if (props.work.priority == value) {
                                        return@event
                                    }

                                    val work = props.work.clone(WorkDTO.serializer())
                                    work.priority = value

                                    GlobalScope.launch {
                                        props.client
                                            .network
                                            .work
                                            .editWork(work)
                                    }

                                    dropdownRef.current
                                        ?.let { Dropdown.getOrCreateInstance(it) }
                                        ?.hide()
                                }
                            }
                            inputGroup {
                                btn(BootstrapColor.SECONDARY, "V") {
                                    props.changePriority(+1)

                                    dropdownRef.current
                                        ?.let { Dropdown.getOrCreateInstance(it) }
                                        ?.hide()

                                }
                                btn(BootstrapColor.PRIMARY, "VV") {
                                    props.changePriority(+100)

                                    dropdownRef.current
                                        ?.let { Dropdown.getOrCreateInstance(it) }
                                        ?.hide()
                                }
                            }

                        }
                    }

                } else {
                    dropdownHeader("Работы:")
                    workTypesForCurrent.forEach {
                        ReactHTML.a {
                            className = ClassName("dropdown-item")

                            +it.name.orEmpty()

                            if (props.work.works?.contains(it) == true) {
                                badge(BootstrapColor.SUCCESS, "Назначена", extraClass = ClassName("float-end"))
                            } else {
                                badge(BootstrapColor.DANGER, "Нет", extraClass = ClassName("float-end"))
                            }

                        }
                    }
                }

                dropdownDivider()

                inputGroup(ClassName("p-2")) {
                    name("Статус", ClassName("flex-grow-1"))
                    warn(
                        color = when {
                            props.work.progress == WorkProgress.STARTED -> BootstrapColor.WARNING
                            props.work.progress == WorkProgress.FINISHED -> BootstrapColor.SUCCESS
                            else -> BootstrapColor.SECONDARY
                        },
                        text = props.work.progress?.displayName ?: "Не начата"
                    )
                    warn(
                        color = when {
                            props.work.approved -> BootstrapColor.SUCCESS
                            props.work.progress == WorkProgress.FINISHED -> BootstrapColor.WARNING
                            else -> BootstrapColor.SECONDARY
                        },
                        text = if (props.work.approved) "Принята" else "Не принята"
                    )

                    if (canEditWork) {
                        if (props.work.approved) {
                            btn(BootstrapColor.DANGER, "Отклонить") {

                                val work = props.work.clone(WorkDTO.serializer())
                                work.approved = false

                                GlobalScope.launch {
                                    props.client
                                        .network
                                        .work
                                        .editWork(work)
                                }
                            }
                        } else {
                            btn(BootstrapColor.PRIMARY, "Принять") {

                                val work = props.work.clone(WorkDTO.serializer())
                                work.approved = true

                                GlobalScope.launch {
                                    props.client
                                        .network
                                        .work
                                        .editWork(work)
                                }
                            }
                        }
                    } else if (props.client.auth.currentUser == props.work.user) {

                        val nextStage = when {
                            props.work.progress == WorkProgress.STARTED -> WorkProgress.FINISHED
                            props.work.progress == WorkProgress.FINISHED -> null
                            else -> WorkProgress.STARTED
                        }

                        btn(BootstrapColor.SECONDARY, nextStage?.displayName ?: "Не начата") {

                            val work = props.work.clone(WorkDTO.serializer())
                            work.progress = nextStage

                            GlobalScope.launch {
                                props.client
                                    .network
                                    .work
                                    .editWorkProgress(work)
                            }
                        }
                    }
                }

                div {
                    className = ClassName("p-2")

                    button {
                        className = BootstrapButton.painted(BootstrapColor.PRIMARY) + ClassName("w-100")

                        + "Мерки"

                        onClick = {
                            val order = order

                            if (order != null) {
                                WorkerMainForm.openAction(props.client).invoke(order)
                            }
                        }
                    }

                }

                dropdownDivider()

                OrderWorkList {
                    this.client = props.client
                    this.order = order ?: OrderDTO()
                }

                dropdownDivider()
                //вывести дату отправки

                val packageTime = order?.personalData()?.packageTime?.let { Date(it) }?.toISOString()?.takeWhile { it != 'T' }

                dropdownHeader("Дата отправки:  " + packageTime)


                dropdownDivider()
                dropdownHeader("Удалить:")
                div {
                    className = ClassName("px-2 w-100")

                    button {
                        className = ClassName("btn btn-sm btn-outline-danger")

                        +"Отменить работу"

                        onClick = {
                            dropdownRef.current
                                ?.let { Dropdown.getOrCreateInstance(it) }
                                ?.hide()

                            GlobalScope.launch {
                                props.client
                                    .network
                                    .work
                                    .delWork(
                                        props.work
                                    )

                                props.reloadAll()
                            }
                        }
                    }
                }
            }
        }
    )

}