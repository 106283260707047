package ru.arty_bikini.crm_frontend.ui.alert

import csstype.*
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.event.EventHandler
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.get
import ru.arty_bikini.crm_frontend.util.plus
import ru.arty_bikini.crm_frontend.util.update
import kotlin.js.Date

val AlertContainer = tryFC<ClientProps> { props ->

    val alerts = useState(emptyList<ShowAlertEvent>())

    useEffectOnce {
        props.client.event.registerHandler(ShowAlertEvent.ID, EventHandler { event ->
            alerts.update { it + event }
        })
    }

    useEffectOnce {
        var active = true

        GlobalScope.launch {
            while (active) {
                delay(1_000)

                alerts.update {
                    val now = Date.now()
//                    console.log(arrayOf(now, it.map { it.showTo.getTime() }.toTypedArray()))

                    it.filter { it.showTo.getTime() > now }
                }
            }
        }

        cleanup {
            active = false
        }
    }

    div {
        css {
            position = Position.absolute

            right = 0.px
            top = 0.px

            width = 0.px
            height = 0.px

            overflow = Overflow.visible

            zIndex = integer(10_000)
        }

        div {
            css {
                position = Position.absolute

                right = 0.px
                top = 0.px

                width = 300.px
            }

            className = className + ClassName("vstack gap-1 mt-1 me-1")

            alerts.get().forEach { alert ->
                AlertItem {
                    this.client = props.client
                    this.alert = alert
                    this.remove = { alerts.update { it.filter { it != alert } }}
                }
            }
        }
    }

}