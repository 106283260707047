package ru.arty_bikini.crm_frontend

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlin.properties.ReadOnlyProperty
import kotlin.reflect.KProperty

class SimpleCache<T>(val default: T, val init: suspend () -> T?) : ReadOnlyProperty<CacheModule, T> {

    companion object {
        val noOp: () -> Unit = {}
    }

    private var value: T? = null

    private var initInProgress: Boolean = false

    private val onUpdate: MutableMap<CacheUpdateHandlerId, () -> Unit> = HashMap()
    private var onUpdateNextId = 1

    override fun getValue(thisRef: CacheModule, property: KProperty<*>): T {
        return get()
    }

    fun get(): T {
        if (value == null && !initInProgress) {
            initInProgress = true

            GlobalScope.launch {

                try {
                    value = init()

                    if (value != null) {
                        onUpdate.values.forEach { action -> action() }
                    }

                } finally {
                    initInProgress = false
                }

            }
        }
        return value ?: default
    }

    fun subscribe(onUpdate: () -> Unit): CacheUpdateHandlerId {
        val pos = CacheUpdateHandlerId(onUpdateNextId++)
        this.onUpdate.put(pos, onUpdate)
        return pos
    }

    fun unsubscribe(handleId: CacheUpdateHandlerId) {
        this.onUpdate.remove(handleId)
    }

}

data class CacheUpdateHandlerId(val id: Int)