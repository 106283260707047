package ru.arty_bikini.crm_frontend.ui.input.table

import react.StateInstance
import react.useState
import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm_frontend.util.StringUtils

data class TableColumnState(
    val id: String,
    val filterQuery: String? = null,
    val hidden: HiddenState = HiddenState.DEFAULT,
)

class SortRule(
    val id: String,
    val direction: SortDirection,
    val specialName: String? = null
)

enum class SortDirection(override val displayName: String) : HasDisplayValue {
    ASC("А -> Я"),
    DESC("Я -> А")
}

enum class HiddenState {
    DEFAULT,
    HIDDEN,
    SHOWN
}