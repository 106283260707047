package ru.arty_bikini.crm_frontend.debug

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.file.FileDTO

@Serializable
class HintDTOClient(
    override var id: Int,

    var key: String,
    var file: FileDTO?,
    var content: String,

) : EntityDTO()