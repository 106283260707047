package ru.arty_bikini.crm_frontend.ui.root

import ru.arty_bikini.crm_frontend.table.ArchiveTablePage
import ru.arty_bikini.crm_frontend.table.GoogleTablePage
import ru.arty_bikini.crm_frontend.table.LeadTablePage
import ru.arty_bikini.crm_frontend.table.admin.ClientConfigPage
import ru.arty_bikini.crm_frontend.table.admin.FileListPage
import ru.arty_bikini.crm_frontend.table.admin.UserListPage
import ru.arty_bikini.crm_frontend.table.report.ConversionTablePage
import ru.arty_bikini.crm_frontend.table.report.MoneyTablePage
import ru.arty_bikini.crm_frontend.table.report.TrainerPaymentTablePage
import ru.arty_bikini.crm_frontend.table.config.*
import ru.arty_bikini.crm_frontend.table.production.ProductionTablePage
import ru.arty_bikini.crm_frontend.table.production.worker.OwnWorksTablePage
import ru.arty_bikini.crm_frontend.table.production.worker.WorkerProductionTablePage

object RouteList {
    val all = buildRoutes {
        single("/table/lead", "Лиды", LeadTablePage)
        group("Производство") {
            route("/table/production/manager", "Производство (Манагер)", ProductionTablePage)
            route("/table/production/worker", "Производство (Сотрудник)", WorkerProductionTablePage)
        }
        single("/table/import-google", "Мерки из Формы", GoogleTablePage)
        single("/table/own-works", "Мои работы", OwnWorksTablePage)
        group("Отчеты") {
            route("/table/report/archive", "Архив", ArchiveTablePage)
            route("/table/report/conversion", "Конверсия", ConversionTablePage)
            route("/table/report/trainer", "Вознаграждения тренеру", TrainerPaymentTablePage)
            route("/table/report/money", "Расходы/Доходы", MoneyTablePage)
        }
        group("Система") {
            route("/admin/user", "Сотрудники", UserListPage)
            divider("Техническое")
            route("/admin/file", "Файлы", FileListPage)
        }
        group("Настройки") {
            route("/dict/trainer", "Тренера", TrainerTablePage)
            divider("Заказ")
            route("/dict/product-type", "Товары", ProductTypeTablePage)
            route("/dict/additional-item-type", "Допы", AdditionalItemsTablePage)
            route("/dict/rhinestone-type", "Стразы", AdditionalItemsTablePage)
            route("/dict/express-type", "Срочность", RhinestoneTypesTablePage)
            route("/dict/straps-type", "Лямки", ExpressTablePage)
            divider("Прочее")
            route("/dict/calc-preset", "Шаблоны калькулятора", CalcPresetTablePage)
            route("/dict/order-data-type", "Настройки мерок и импорта", OrderDataTypeTablePage)
            route("/dict/work-type", "Виды работ", WorkTypeTablePage)
        }
        group("Профиль") {
            route("/settings/client", "Настройки клиента", ClientConfigPage)

        }
    }
}