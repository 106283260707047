package ru.arty_bikini.crm_frontend.ui.input.table.data

import ru.arty_bikini.crm_frontend.ui.input.table.ColumnStateHandler
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntity

class DataSource<T : Any> constructor(

    val state: DataSourceLoadingState,
    val currentData: List<TablePanelEntity<T>>,

    val setSort: (ColumnStateHandler<T>) -> Unit,
    val clientSideSorting: Boolean

)

enum class DataSourceLoadingState {
    LOADING,
    LOADED,
    ERROR,
}