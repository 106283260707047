package ru.arty_bikini.crm_frontend.table.admin

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.file.FileDTO
import ru.arty_bikini.crm.dto.packet.file.GetReportResponse
import ru.arty_bikini.crm_frontend.debug.EntityFilter
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.input.table.*

val FileListPage = commonPage(requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)) { props ->

    var report: GetReportResponse? by useState()

    useEffectOnce {
        GlobalScope.launch {
            report = props.client
                .network
                .file
                .getReport()
        }
    }

    cardLightShadow {
        div {
            className = ClassName("vstack gap-1")

            badge(BootstrapColor.INFO, "Всего файлов: ${report?.count}")
            badge(BootstrapColor.INFO, "Всего занято места: ${formatSize(report?.totalSize)}")
        }
    }

    TablePanelEntityPage<FileDTO, EntityFilter>(props.client, "t-file", { filter -> file.getPage(filter.page)?.filePage }, ::EntityFilter) {

        addText(FieldFlag.ReadOnlyProp, "Название", FileDTO::sha256)
        addText(FieldFlag.ReadOnlyProp, "Превью", FileDTO::location)
        addText(FieldFlag.ReadOnlyValue, "Размер (МБ)") { formatSize(this.size) }
        addText(FieldFlag.ReadOnlyValue, "Заргужен") { this.uploadBy?.login }
        addDateTime(FieldFlag.ReadOnlyProp, "Заргужен", FileDTO::uploadAt)

    }
}

fun formatSize(size: Long?, loop: Boolean = true): String {

    size ?: return "<null>"

    val sizes = mapOf<Long, String>(
        1L to "Б",
        1024L to "КБ",
        1024L * 1024 to "МБ",
        1024L * 1024 * 1024 to "ГБ",
        1024L * 1024 * 1024 * 1024 to "ТБ",
    )



    for ((min, name) in sizes.entries.sortedByDescending { it.key }) {
        if (min <= size) {
            val sizeCut = size / min
            val main = "$sizeCut $name"
            if (sizeCut <= 10 && loop) {
                val rem = size - sizeCut * min
                return main + " " + formatSize(rem, false)

            }
            return main
        }
    }

    return "$size Б"
}