package ru.arty_bikini.crm_frontend.ui.input.form

import csstype.ClassName
import csstype.Overflow
import csstype.px
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import org.w3c.files.get
import react.ChildrenBuilder
import react.createRef
import react.dom.html.InputType
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.useState
import ru.arty_bikini.crm.dto.enums.OrderFileCategory
import ru.arty_bikini.crm.dto.file.OrderFileDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.plus

class FormInputBuilderImage(val client: ClientCore, val cb: ChildrenBuilder, val entity: OrderDTO?, val readOnly: Boolean) {
    operator fun invoke(category: OrderFileCategory?) {

        val uploadRef = createRef<HTMLInputElement>()

        var uploadState by useState(UploadState.NONE)

        if (entity == null) {
            cb.alertDanger { + "Файлы поддерживаются только в заказах" }
            return
        }

        cb.apply {
            category?.displayName?.let { h4 { +it } }

            div {
                className = ClassName("hstack gap-2 align-items-stretch flex-wrap")

                val files = entity.files
                    ?.filter { category == null || it.category == category }
                    ?.sortedWith(compareBy<OrderFileDTO> { it.priority }.thenBy { it.id })
                    ?: emptyList()

                files.forEach { file ->

                    ImageCard {
                        this.client = this@FormInputBuilderImage.client
                        this.order = entity
                        this.file = file
                        this.readOnly = readOnly
                    }

                }

                if (category != null && !readOnly) {
                    div {
                        css {
                            this.maxWidth = 200.px
                        }

                        className = className + ClassName("card")


                        div {
                            className = ClassName("card-body vstack gap-3")

                            if (uploadState != UploadState.NONE) {
                                alert(uploadState.color) {
                                    +uploadState.message
                                }
                            }

                            div {
                                input {
                                    className = ClassName("form-control form-control-sm")
                                    type = InputType.file

                                    this.ref = uploadRef
                                }
                            }

                            div {
                                button {
                                    className = BootstrapButton.outline(BootstrapColor.PRIMARY)

                                    +"Загрузить"

                                    onClick = onClick@{

                                        if (uploadState.inProgress) {
                                            return@onClick
                                        }

                                        val file = uploadRef.current
                                            ?.files
                                            ?.get(0)

                                        if (file == null) {
                                            uploadState = UploadState.NOT_A_FILE
                                            return@onClick
                                        }

                                        GlobalScope.launch {

                                            uploadState = UploadState.STARTED

                                            val dto = client
                                                .network
                                                .file
                                                .upload(file)
                                                ?.fileDTO

                                            if (dto == null) {
                                                uploadState = UploadState.ERROR
                                                return@launch
                                            }

                                            uploadState = UploadState.LINKING

                                            val lastId = entity.files?.maxOf { it.priority } ?: 1

                                            val result = client
                                                .network
                                                .file
                                                .addFileToOrder(dto, entity, lastId + 1, category)

                                            if (result?.ok == true) {
                                                uploadState = UploadState.COMPLETE
                                            } else {
                                                uploadState = UploadState.ERROR
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

enum class UploadState(val color: BootstrapColor, val message: String, val inProgress: Boolean) {
    NOT_A_FILE(BootstrapColor.DANGER, "Не указан файл", false),
    NONE(BootstrapColor.SECONDARY, "", false),
    STARTED(BootstrapColor.INFO, "Идет загрузка... (10%)", true),
    ERROR(BootstrapColor.DANGER, "Ошибка загрузки файла!", false),
    LINKING(BootstrapColor.INFO, "Идет загрузка... (90%)", true),
    COMPLETE(BootstrapColor.SUCCESS, "Загрузка завершена.", false),
}

external interface ImageCardProps : ClientProps {
    var file: OrderFileDTO
    var order: OrderDTO
    var readOnly: Boolean
}

private val ImageCard = tryFC<ImageCardProps> { props ->

    var commentValue by useState(props.file.comment ?: "")

    div {
        css {
            this.maxWidth = 200.px
        }

        className = className + ClassName("card")

        img {
            className = ClassName("card-img-top")
            src = props.file.file?.location ?: "<null>"

            css {
                this.maxWidth = 200.px
                this.maxHeight = 200.px
            }

            onClick = {
                props.client
                    .event
                    .showImage(props.file)
            }
        }

        div {
            className = ClassName("vstack gap-1 p-1")
            div {
                className = ClassName("input-group input-group-sm")

                input {
                    className = ClassName("form-control form-control-sm")

                    value = commentValue

                    if (props.readOnly) {
                        readOnly = true
                        disabled = true
                    }

                    onChange = {
                        commentValue = it.target.value
                    }
                    onBlur = {
                        console.log(arrayOf("blur", commentValue))

                        GlobalScope.launch {
                            props.client
                                .network
                                .file
                                .editFileOfOrder(props.file, commentValue)


                        }
                    }
                }

                if (!props.readOnly) {
                    button {
                        className = BootstrapButton.outline(BootstrapColor.DANGER) // + ClassName("btn-close")

                        +"❌"

                        onClick = {
                            GlobalScope.launch {
                                val confirm = window.confirm("Вы хотите удалить картинку. Удалить?")
                                if (confirm) {
                                    props.client
                                        .network
                                        .file
                                        .unlinkFileFromOrder(props.file)
                                }
                            }
                        }
                    }
                }
            }
            div {

                css {
                    maxHeight = 50.px
                    overflow = Overflow.hidden
                }

                className = className

                +props.file.comment.orEmpty()

            }
        }

        title = props.file.comment.orEmpty() + "\n (Нажмите по картинке для увелечения)"
    }
}