package ru.arty_bikini.crm_frontend.form.calc

import csstype.ClassName
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.useEffect
import react.useState
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.stone.CalcPresetDTO
import ru.arty_bikini.crm.dto.orders.stone.CalcPresetRuleDTO
import ru.arty_bikini.crm.dto.orders.stone.OrderRhinestoneAmountDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.modal.EntityTrackRule
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.*
import kotlin.js.Date
import kotlin.math.min
import kotlin.random.Random

val CalculatorForm = createModal<OrderDTO>(
    title = { "Калькулятор страз: $name" },
    trackEntity = EntityTrackRule.order()
) { props ->


    val rhinestoneTypes = useCache(props.client.cache.rhinestoneTypes)
        .filter { it.visible }
        .sortedBy { it.priority }

    val calcPresets = useCache(props.client.cache.calcPresets)
    val trainers = useCache(props.client.cache.trainers)


    val productTypes = useCache(props.client.cache.productTypes)
    val expressTypes = useCache(props.client.cache.expressTypes)
    val strapsTypes = useCache(props.client.cache.strapsTypes)
    val additionalItems = useCache(props.client.cache.additionalItems)


    var calculatorState by useState<CalculatorState> { CalculatorState() }
    var version by useIncrementor()
    var visibleStones by useState<List<Int>>(emptyList())

    useEffect(rhinestoneTypes, props.entity.stones?.joinToString { it.rhinestoneType?.id.toString() }) {
        var result = visibleStones
        var updated = false

        val saved = props.entity.stones
            ?.asSequence()
            ?.map { it.rhinestoneType?.id }
            ?.filterNotNull()
            ?.filter { it !in result }
            ?.distinct()
            ?.toList()


        if (!saved.isNullOrEmpty()) {
            result = result + saved
            updated = true
        }

        val first = rhinestoneTypes
            .asSequence()
            .take(3)
            .filter { it.id !in result }
            .map { it.id }
            .toList()

        if (first.isNotEmpty()) {
            result = result + first
            updated = true
        }

//        console.log(arrayOf(
//            visibleStones.toTypedArray(),
//            saved?.toTypedArray(),
//            first.toTypedArray(),
//            result.toTypedArray()
//        ))

        if (updated) {
            visibleStones = result
        }
    }


    val initialCost = props.entity.personalData().payment
    val baseCost = props.entity.product?.paymentNonStone
    val expressCost = props.entity.express?.cost
    val strapsCost = props.entity.design().straps?.count

    val extrasItemsCost = additionalItems
        .asSequence()
        .filter { (props.entity.price?.contains(it) ?: false) }
        .sumOf { it.count }

    val trainer = props.entity.personalData().trainer
    val discountCount = trainer?.discountCount ?: 0
    val discountPercent = initialCost * (trainer?.discountPercent ?: 0) / 100
    val discount: Int = when {
        discountCount < 1 -> discountPercent
        discountPercent < 1 -> discountCount
        else -> min(discountCount, discountPercent)
    }.coerceIn(0, initialCost)

    val baseForStones = (initialCost + discount - (baseCost ?: 0) - (expressCost ?: 0) - (strapsCost ?: 0) - extrasItemsCost)


    useEffect(props.entity.id, props.entity.version) {
        calculatorState = recalculate(baseForStones, emptyMap())
        version++
    }


    fun setRule(newRule: CalcPresetRuleDTO) {
        val rules = calculatorState.rules.toMutableMap()

        val stoneId = newRule.stone?.id ?: return
        rules[stoneId] = newRule

        calculatorState = recalculate(baseForStones, rules)
        version++
    }


    cardGreenCollapse("Калькулятор страз", ClassName("vstack gap-2"), defaultVisible = false) {

        FormInput(props.client, props.entity) {

            onSave {
                props
                    .client
                    .network
                    .order
                    .saveOrder(it)
            }

            content { entity ->

                row {
                    colLg(6) {
                        row {
                            colSm(6) {
                                inputGroup {
                                    name("Базовая цена")
                                    addCost(FieldFlag.NotNull) { personalData()::payment }
                                }
                            }
                            colSm(6) {
                                badge(
                                    color = BootstrapColor.SECONDARY,
                                    text = "Сумма заказа: ${StringUtils.printNumber(initialCost)}",
                                    extraClass = ClassName("float-end mt-1")
                                )
                            }
                        }
                        row {
                            colSm(6) {
                                inputGroup {
                                    name("Тренер")
                                    addSelect(FieldFlag.Nullable, trainers, { it.name }, { personalData()::trainer })
                                }
                            }
                            colSm(6) {
                                if (discount == 0) {
                                    badge(
                                        color = BootstrapColor.LIGHT,
                                        text = "Скидка: Нет",
                                        extraClass = ClassName("float-end mt-1")
                                    )
                                } else {
                                    val discountPercentReal = 100 * discount / (initialCost + discount)

                                    div {
                                        className = ClassName("vstack gap-1 align-items-end")
                                        badge(
                                            color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                            text = "Скидка ($discountPercentReal%): ${StringUtils.printNumber(discount)}",
                                        )
                                        badge(
                                            color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                            text = "Общая сумма заказа: ${StringUtils.printNumber(initialCost + discount)}",
                                        )
                                    }
                                }
                            }
                        }

                        row {
                            colSm(6) {
                                inputGroup {
                                    name("Товар")
                                    addSelect(FieldFlag.Nullable, productTypes, { it.name }) { this::product }
                                }
                            }

                            colSm(6) {

                                badge(
                                    color = if ((baseCost ?: 0) > 0) BootstrapColor.SECONDARY else BootstrapColor.LIGHT,
                                    text = "Базовая цена: ${StringUtils.printNumber(baseCost)}",
                                    extraClass = ClassName("float-end mt-1")
                                )
                            }
                        }
                        row {
                            colSm(6) {
                                inputGroup {
                                    name("Срочность")
                                    addSelect(FieldFlag.Nullable, expressTypes, { "${it.minDays} - ${it.maxDays} дней" }) { this::express }

                                }
                            }

                            colSm(6) {
                                badge(
                                    color = if ((expressCost ?: 0) > 0) BootstrapColor.SECONDARY else BootstrapColor.LIGHT,
                                    text = "Надбавка: ${StringUtils.printNumber(expressCost)}",
                                    extraClass = ClassName("float-end mt-1")
                                )
                            }
                        }
                        row {
                            colSm(6) {
                                inputGroup {
                                    name("Лямки")
                                    addSelect(FieldFlag.Nullable, strapsTypes, { it.name }) { this.design()::straps }
                                }
                            }

                            colSm(6) {
                                badge(
                                    color = if ((strapsCost ?: 0) > 0) BootstrapColor.SECONDARY else BootstrapColor.LIGHT,
                                    text = "Надбавка: ${StringUtils.printNumber(strapsCost)}",
                                    extraClass = ClassName("float-end mt-1")
                                )
                            }
                        }
                    }
                }

                AdditionalItemsInput(props.client, props.entity, false, true)


                row {
                    colLg(6) {
                        badge(
                            color = if ((baseForStones) > 0) BootstrapColor.SECONDARY else BootstrapColor.DANGER,
                            text = "Всего допов: ${StringUtils.printNumber(initialCost - baseForStones)}",
                            extraClass = ClassName("float-end mt-1")
                        )
                    }
                }
                row {
                    colLg(6) {
                        row {
                            colSm(6) {
                                inputGroup {
                                    name("На стразы")
                                    addCost(
                                        flag = FieldFlag.NotNull,
                                        get = { baseForStones.coerceIn(0, initialCost) },
                                        set = { personalData().payment = it + initialCost - baseForStones }
                                    )
                                }
                            }
                            colSm(6) {
                                badge(
                                    color = if ((baseForStones) > 0) BootstrapColor.SECONDARY else BootstrapColor.DANGER,
                                    text = "На стразы осталось: ${StringUtils.printNumber(baseForStones)}",
                                    extraClass = ClassName("float-end mt-1")
                                )
                            }
                        }
                    }
                }

            }
        }

        row {
            colLg {
                +"Пресеты"
            }
        }
        row {
            val presets = calcPresets
                .sortedBy { it.priority }

            fun CalcPresetDTO.rules() = rules?.associateBy { it.stone?.id ?: 0 } ?: emptyMap()

            for (i in 0 until 3) {
                val preset = presets.getOrNull(i) ?: continue

                colSm(3) {
                    button {
                        className = BootstrapButton.outline(BootstrapColor.SECONDARY)

                        +(preset.name ?: preset.id.toString())

                        onClick = {
                            calculatorState = recalculate(baseForStones, preset.rules())
                            version++
                        }
                    }
                }
            }

            colSm(3) {
                dropdown("Еще") {
                    for (i in 3 until presets.size) {
                        val preset = presets.getOrNull(i) ?: continue

                        dropdownItem(preset.name ?: preset.id.toString()) {
                            calculatorState = recalculate(baseForStones, preset.rules())
                            version++
                        }

                    }
                    dropdownDivider()
                    dropdownItem("Сохранить текущий") {
                        GlobalScope.launch {
                            val name = window.prompt("Название:", "new_" + (Random(Date.now().toLong()).nextInt(9000) + 1000))

                            props.client
                                .network
                                .stone
                                .addPreset(CalcPresetDTO().apply {
                                    this.name = name
                                    this.priority = 1000
                                    this.rules = calculatorState.rules.values.toList().filter { it.auto || it.value > 0 }
                                })
                        }
                    }
                }
            }
        }



        row {
            colLg(2) { inputColumn { name("Название") } }
            colLg(4) { inputColumn { name("Нужно") } }
            colLg(6) { inputColumn { name("Результат") } }
        }

        row {
            colLg(2) { }
            colLg(4) { }
            colLg(3) { inputColumn { name("Кол-во") } }
            colLg(3) { inputColumn { name("Стоимость") } }
        }


        for (type in rhinestoneTypes.filter { it.id in visibleStones }) {
            row {
                colLg(2) {
                    ReactHTML.div {
                        className = ClassName("vstack")

                        span {
                            +(type.manufacturer + " " + type.sizeType)
                        }

                        badge(BootstrapColor.LIGHT, "Цена: ${type.price}")
                    }
                }
                colLg(4) {
                    CalculatorInput {
                        this.type = type
                        this.version = version
                        this.currentRule = calculatorState.rules[type.id] ?: CalcPresetRuleDTO().apply { this.stone = type }
                        this.storedRule = props.entity.presetRules?.find { it.stone?.id == type.id }
                        this.setRule = { setRule(it) }
                    }
                }

                colLg(6) {
                    val actual = props.entity.stones
                    CalculatorOutput {
                        this.type = type
                        this.amount = calculatorState.getAmount(type)
                        this.stored = actual?.find { it.rhinestoneType?.id == type.id }?.count
                    }
                }
            }
        }
        row {
            colSm(6) {
                dropdown("Другие...") {
                    for (type in rhinestoneTypes.filter { it.id !in visibleStones }) {
                        dropdownItem(type.manufacturer + " " + type.sizeType) {
                            visibleStones = visibleStones + type.id
                        }
                    }
                }
            }

            colSm(6) {
                inputGroup() {
                    name("Остаток")
                    value(
                        text = StringUtils.printNumber(calculatorState.remaining),
                        color = when {
                            calculatorState.remaining < -250 -> BootstrapColor.DANGER
                            calculatorState.remaining > 250 -> BootstrapColor.WARNING
                            calculatorState.remaining in -250..250 -> BootstrapColor.SUCCESS
                            else -> BootstrapColor.LIGHT
                        }.let { ClassName("bg-${it.suffix} bg-opacity-25 flex-grow-1") },
                    )
                }
            }
        }
        row {
            colLg {
                inputColumn { name("Результаты") }
            }
        }
        row {
            colLg(6) {
                div {
                    className = ClassName("vstack gap-1 align-items-end")

                    badge(
                        color = if ((baseForStones - calculatorState.remaining) > 0) BootstrapColor.INFO else BootstrapColor.LIGHT,
                        text = "Стразы: ${StringUtils.printNumber(baseForStones - calculatorState.remaining)}",
                        extraClass = ClassName("")
                    )
                    badge(
                        color = if ((initialCost - baseForStones) > 0) BootstrapColor.SECONDARY else BootstrapColor.DANGER,
                        text = "Допы: ${StringUtils.printNumber(initialCost)}",
                        extraClass = ClassName("")
                    )
                    badge(
                        color = when {
                            (initialCost - calculatorState.remaining) <= 0 -> BootstrapColor.DANGER
                            calculatorState.remaining in -250 .. 250 -> BootstrapColor.SUCCESS
                            else -> BootstrapColor.SECONDARY
                        },
                        text = "Фактическая стоимость: ${StringUtils.printNumber(initialCost - calculatorState.remaining)}",
                        extraClass = ClassName("")
                    )
                    badge(
                        color = when {
                            calculatorState.remaining < -250 -> BootstrapColor.DANGER
                            calculatorState.remaining > 250 -> BootstrapColor.WARNING
                            else -> BootstrapColor.LIGHT
                        },
                        text = "Остаток:  ${StringUtils.printNumber(calculatorState.remaining)}",
                    )
                    badge(
                        color = when {
                            (initialCost) <= 0 -> BootstrapColor.DANGER
                            calculatorState.remaining in -250 .. 250 -> BootstrapColor.LIGHT
                            else -> BootstrapColor.SECONDARY
                        },
                        text = "Заявленная стоимость: ${StringUtils.printNumber(initialCost)}",
                        extraClass = ClassName("")
                    )
                }
            }

            colLg(3) {
                button {

                    val actual = props.entity.stones
                    if (actual.isNullOrEmpty()) {
                        className = BootstrapButton.outline(BootstrapColor.PRIMARY)
                        +"Сохранить"
                    } else {
                        className = BootstrapButton.outline(BootstrapColor.DANGER)
                        +"Заменить"
                    }

                    onClick = {
                        GlobalScope.launch {

                            props.client
                                .network
                                .stone
                                .saveOrderStones(
                                    order = props.entity,
                                    calculatorState.amount.map { (k, v) ->
                                        OrderRhinestoneAmountDTO().apply {
                                            this.order = OrderDTO().apply { id = props.entity.id }
                                            this.rhinestoneType = rhinestoneTypes.find { it.id == k }
                                            this.count = v
                                        }
                                    }
                                )

                            val entity = props.entity.clone(OrderDTO.serializer())

                            entity.presetRules = calculatorState.rules.values.toList()

                            props.client
                                .network
                                .order
                                .saveOrder(entity)

                        }
                    }
                }
            }

            colLg(3) {
                div {
                    className = ClassName("vstack gap-1 align-items-end")

                    val stonesSaved = props.entity.stones?.sumOf { it.count * (it.rhinestoneType?.price ?: 0) } ?: return@div
                    val remaining = baseForStones - stonesSaved

                    badge(
                        color = if ((baseForStones - remaining) > 0) BootstrapColor.INFO else BootstrapColor.LIGHT,
                        text = "(Сохранено) Стразы: ${StringUtils.printNumber(baseForStones - remaining)}",
                        extraClass = ClassName("")
                    )
                    badge(
                        color = if ((initialCost - baseForStones) > 0) BootstrapColor.SECONDARY else BootstrapColor.DANGER,
                        text = "(Сохранено) Допы: ${StringUtils.printNumber(initialCost)}",
                        extraClass = ClassName("")
                    )
                    badge(
                        color = when {
                            (initialCost - remaining) <= 0 -> BootstrapColor.DANGER
                            remaining in -250 .. 250 -> BootstrapColor.SUCCESS
                            else -> BootstrapColor.SECONDARY
                        },
                        text = "(Сохранено) Фактическая стоимость: ${StringUtils.printNumber(initialCost - remaining)}",
                        extraClass = ClassName("")
                    )
                    badge(
                        color = when {
                            remaining < -250 -> BootstrapColor.DANGER
                            remaining > 250 -> BootstrapColor.WARNING
                            else -> BootstrapColor.LIGHT
                        },
                        text = "(Сохранено) Остаток:  ${StringUtils.printNumber(remaining)}",
                    )
                    badge(
                        color = when {
                            (initialCost) <= 0 -> BootstrapColor.DANGER
                            remaining in -250 .. 250 -> BootstrapColor.LIGHT
                            else -> BootstrapColor.SECONDARY
                        },
                        text = "(Сохранено) Заявленная стоимость: ${StringUtils.printNumber(initialCost)}",
                        extraClass = ClassName("")
                    )
                }
            }
        }

    }
}