package ru.arty_bikini.crm_frontend.ui.input.table.data.month

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.useEffect
import react.useState
import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.debug.EntityFilter
import ru.arty_bikini.crm_frontend.debug.OrderFilter
import ru.arty_bikini.crm_frontend.network.NetworkModule
import ru.arty_bikini.crm_frontend.network.ServerResponse
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntity
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSource
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.DateUtils
import ru.arty_bikini.crm_frontend.util.usePersistent

external interface MonthPagerDataProviderProps<T : Any, F : EntityFilter> : ClientProps {

    var loadPage: suspend (F) -> ServerResponse<PageDTO<T>>
    var setMonth: (F, Long, Long) -> Unit

    var defaultFilter: () -> F

    var content: (ChildrenBuilder, DataSource<T>) -> Unit
}

private val MonthPagerDataProvider = tryFC<MonthPagerDataProviderProps<*, *>> { bounded(it) }

fun <T : Any, F : EntityFilter> ChildrenBuilder.bounded(props: MonthPagerDataProviderProps<T, F>) {

    val (dataCache, setDataCache) = useState<List<TablePanelEntity<T>>> { emptyList() }

    var loading by useState(DataSourceLoadingState.LOADING)
    var filter by useState(props.defaultFilter)

    var currentMonth by useState(DateUtils.loaded.monthStart)

    var lastUpdate by usePersistent(DateUtils.loaded, track = false)

    useEffect(filter, currentMonth) {
        loading = DataSourceLoadingState.LOADING
        setDataCache { emptyList() }

        var active = true

        var currentUpdate = DateUtils.now()
        lastUpdate = currentUpdate

        val activeFilter = props.defaultFilter()

        activeFilter.orderColumn = filter.orderColumn
        activeFilter.orderDirection = filter.orderDirection

        GlobalScope.launch {
            var currentPage = 0

            while (active && currentUpdate.toTimestamp() >= lastUpdate.toTimestamp()) {
                activeFilter.page = currentPage
                props.setMonth(activeFilter, currentMonth.monthStart.toTimestamp(), currentMonth.monthEnd.toTimestamp())

                val page = props.loadPage.invoke(activeFilter).body
                if (page == null) {
                    loading = DataSourceLoadingState.ERROR
                    return@launch
                }

                if (currentUpdate != lastUpdate) {
                    console.log(arrayOf("cache miss"))
                    return@launch
                }

                setDataCache { it + page.data.map { TablePanelEntity(it) } }
                loading = DataSourceLoadingState.LOADED

                console.log(arrayOf("page", currentUpdate.toTimestamp().hashCode(), currentPage, activeFilter.page, page.page, page.totalPages))
                currentPage++

                if (page.page >= page.totalPages) {
                    return@launch
                }
            }
        }

        cleanup { active = false }
    }

    val dataSource = DataSource<T>(
        state = loading,
        currentData = dataCache,
        setSort = {
            val new = props.defaultFilter()

            it.primarySort?.let { (col, sort) ->
                new.orderColumn = col?.prop?.sortName
                new.orderDirection = sort
            }

            if (new.orderColumn == filter.orderColumn && new.orderDirection == filter.orderDirection) {
                return@DataSource
            }

            console.log("filter")
            filter = new
        },
        clientSideSorting = false
    )

    props.content(this, dataSource)

    MonthPagerComponent {
        this.setMonth = setMonth@{ newMonth ->
            if (currentMonth == newMonth) {
                return@setMonth
            }
            console.log("currentMonth")
            currentMonth = newMonth
        }

        this.currentMonth = currentMonth
    }
}

fun <T : Any, F : EntityFilter> ChildrenBuilder.MonthPagerDataProvider(
    client: ClientCore,
    loadPage: suspend NetworkModule.(F) -> ServerResponse<PageDTO<T>>,
    setMonth: F.(Long, Long) -> Unit,
    defaultFilter: () -> F,
    content: ChildrenBuilder.(DataSource<T>) -> Unit
) {
    MonthPagerDataProvider {
        val props = this.unsafeCast<MonthPagerDataProviderProps<T, F>>()

        props.client = client

        props.loadPage = { filter -> client.network.loadPage(filter) }
        props.setMonth = setMonth
        props.defaultFilter = defaultFilter

        props.content = content
    }
}

fun ChildrenBuilder.MonthPagerDataProviderOrder(
    client: ClientCore,
    defaultFilter: () -> OrderFilter,
    content: ChildrenBuilder.(DataSource<OrderDTO>) -> Unit
) {
    MonthPagerDataProvider(
        client,
        { filter -> client.network.order.getOrdersPage(filter) },
        { start, end ->
            createdTimeFrom = start
            createdTimeTo = end
        },
        defaultFilter,
        content,
    )
}
