package ru.arty_bikini.crm_frontend.form.config

import csstype.Auto
import csstype.px
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.orders.google.MeasureVariantsDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alertDanger
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.clone

private val json = Json

val GoogleVariantsImport = createModal<String> { props ->
    val raw = props.entity

    var old: List<OrderDataTypeDTO> by useState(emptyList())
    var oldVariants: List<MeasureVariantsDTO> by useState(emptyList())
    useEffectOnce {
        GlobalScope.launch {
            old = props.client.network.orderData.getDataTypes()?.types ?: emptyList()
            oldVariants = props.client.network.orderData.getMeasureVariants()?.measureVariantsDTOList ?: emptyList()
        }
    }

    val imported: List<OrderDataTypeDTO>
    val importedVariants: List<MeasureVariantsDTO>
    try {

        val (raw1, raw2) = json.decodeFromString(ListSerializer(String.serializer()), raw)

        imported = json.decodeFromString(ListSerializer(OrderDataTypeDTO.serializer()), raw1)
        importedVariants = json.decodeFromString(ListSerializer(MeasureVariantsDTO.serializer()), raw2)
    } catch (e: Throwable) {
        e.printStackTrace()

        alertDanger { + "Неправильный формат данных" }
        return@createModal
    }

    fun OrderDataTypeDTO.matchOld(): OrderDataTypeDTO? {
        if (this.googleColumn == -1) {
            return old.firstOrNull { it.name == this.name }
        } else {
            return old.firstOrNull { it.googleColumn == this.googleColumn }
        }
    }
    div {
        css {
            overflow = Auto.auto
        }

        TablePanelEntityAuto(props.client, "f-t-s-google-variant-import-1", imported, OrderDataTypeDTO.serializer()) {

            addText(FieldFlag.ReadOnlyProp, "New name", OrderDataTypeDTO::name, TableColumnRenderOptions(maxWidthPx = 200))
            addText(
                flag = FieldFlag.ReadOnlyValue,
                title = "Old name",
                options = TableColumnRenderOptions(
                    maxWidthPx = 200,
                    extraContent = {
                        if (it.matchOld() == null) {
                            badge(BootstrapColor.DANGER, "new")
                        }
                    }
                )
            ) { matchOld()?.name }
            addText(FieldFlag.ReadOnlyProp, "New google name", OrderDataTypeDTO::googleName, TableColumnRenderOptions(maxWidthPx = 200))
            addText(FieldFlag.ReadOnlyValue, "Old google name", TableColumnRenderOptions(maxWidthPx = 200)) { matchOld()?.googleName }
            addInt(FieldFlag.ReadOnlyProp, "New google column", OrderDataTypeDTO::googleColumn, TableColumnRenderOptions(maxWidthPx = 200))
            addInt(FieldFlag.ReadOnlyValue, "Old google column", TableColumnRenderOptions(maxWidthPx = 200)) { matchOld()?.googleColumn }
            addInt(FieldFlag.ReadOnlyValue, "New google variants", TableColumnRenderOptions(maxWidthPx = 200)) { importedVariants.count { it.orderDataType?.id == id }}
            addInt(FieldFlag.ReadOnlyValue, "Old google variants", TableColumnRenderOptions(maxWidthPx = 200)) { oldVariants.count { it.orderDataType?.id == matchOld()?.id } }
            addButton("Save", TableColumnRenderOptions(disabledIf = { curr ->
                val matchOld = curr.matchOld()?.clone(OrderDataTypeDTO.serializer()) ?: return@TableColumnRenderOptions EnabledResult(true)


                val newVariants = importedVariants.filter { it.orderDataType?.id == it.id }.map { it.clone(MeasureVariantsDTO.serializer()).also { it.id = -1; it.orderDataType = null } }
                val oldVariants = oldVariants.filter { it.orderDataType?.id == matchOld.id }.map { it.clone(MeasureVariantsDTO.serializer()).also { it.id = -1; it.orderDataType = null } }

                matchOld.id = curr.id

                if (
                    json.encodeToString(OrderDataTypeDTO.serializer(), curr) == json.encodeToString(OrderDataTypeDTO.serializer(), matchOld) &&
                    json.encodeToString(ListSerializer(MeasureVariantsDTO.serializer()), newVariants) == json.encodeToString(ListSerializer(MeasureVariantsDTO.serializer()), oldVariants)
                ) {
                    return@TableColumnRenderOptions EnabledResult(false, "Exact match (except id)")
                }
                return@TableColumnRenderOptions EnabledResult(true)
            })) { curr ->

                GlobalScope.launch {
                    val matchOld = curr.matchOld()?.clone(OrderDataTypeDTO.serializer())

                    val newVariants = importedVariants.filter { it.orderDataType?.id == it.id }
                    val oldVariants = oldVariants.filter { it.orderDataType?.id == matchOld?.id }

                    val clone = curr.clone(OrderDataTypeDTO.serializer())
                    if (matchOld != null) {
                        clone.id = matchOld.id
                    } else {
                        clone.id = 0
                    }

                    props.client
                        .network
                        .orderData
                        .saveDataType(clone)
                        ?.orderDataType
                        ?.let { new ->

                            if (oldVariants.size > newVariants.size) {
                                oldVariants.takeLast(oldVariants.size - newVariants.size).forEach { old ->
                                    props.client
                                        .network
                                        .orderData
                                        .deleteMeasureVariant(old)
                                }
                            }

                            newVariants.forEachIndexed { index, variant ->
                                val variantClone = variant.clone(MeasureVariantsDTO.serializer())

                                val oldId = oldVariants.getOrNull(index)?.id ?: 0
                                variantClone.id = oldId
                                variantClone.orderDataType = new

                                props.client
                                    .network
                                    .orderData
                                    .editMeasureVariants(variantClone)
                            }

                        }
                }

            }

        }

        val missing = old.filter { curr -> imported.none { it.matchOld() == curr } }

        TablePanelEntityAuto(props.client, "f-t-s-google-variant-import-2", missing, OrderDataTypeDTO.serializer()) {

            addText(FieldFlag.ReadOnlyValue, "New name", TableColumnRenderOptions(maxWidthPx = 200)) { null }
            addText(FieldFlag.ReadOnlyValue, "Old name", TableColumnRenderOptions(maxWidthPx = 200)) { name }
            addText(FieldFlag.ReadOnlyValue, "New google name", TableColumnRenderOptions(maxWidthPx = 200)) { null }
            addText(FieldFlag.ReadOnlyValue, "Old google name", TableColumnRenderOptions(maxWidthPx = 200)) { googleName }
            addInt(FieldFlag.ReadOnlyValue, "New google column", TableColumnRenderOptions(maxWidthPx = 200)) { null }
            addInt(FieldFlag.ReadOnlyValue, "Old google column", TableColumnRenderOptions(maxWidthPx = 200)) { googleColumn }
            addInt(FieldFlag.ReadOnlyValue, "New google variants", TableColumnRenderOptions(maxWidthPx = 200)) { null }
            addInt(FieldFlag.ReadOnlyValue, "Old google variants", TableColumnRenderOptions(maxWidthPx = 200)) { oldVariants.count { it.orderDataType?.id == id } }

        }
    }


}
