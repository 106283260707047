package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.form.button.ButtonArchiveForm
import ru.arty_bikini.crm_frontend.form.main.MainForm
import ru.arty_bikini.crm_frontend.form.calc.CalculatorForm
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*
import kotlin.math.min

val ClientTable = tryFC<ClientProps> { props ->

    val workTypes = useCache(props.client.cache.workTypes)

    val orders = useAutoReload<OrderDTO> {
        props
            .client
            .network
            .order
            .getClients()
            ?.orders
    }


    TablePanelOrderAuto(props.client, "t-production-client", orders) {

        addNew = {
            props.client
                .network
                .order
                .addOrder(it, ClientType.CLIENT)
        }

        addText(
            flag = FieldFlag.ReadOnlyProp,
            title = "ФИО",
            value = OrderDTO::name,
            options = TableColumnRenderOptions(
                editButtonForm = MainForm,
                extraContent = { entity ->
                    val types = listOf(UserGroup.SEAMSTRESS, UserGroup.GLUER)

                    val hasGoogle = entity.dataGoogle != null
                    val hasData = entity.statusInfo().isDesignAll && entity.statusInfo().isMeasureAll
                    val hasConfirm = entity.statusInfo().isDesignAllTanya && entity.statusInfo().isMeasureAllTanya
                    val needWork =
                        types.any { group ->
                            workTypes
                                .filterBy(group, entity)
                                .any { type ->
                                    entity.worksTMP.none { it.user?.group == group && it.works?.contains(type) == true }
                                }
                        }

                    div {
                        this.className = ClassName("hstack gap-1 mt-1 align-items-stretch")
                        
                        if (needWork) {
                            badge(
                                color = when {
                                    hasData && hasConfirm -> BootstrapColor.DANGER
                                    else -> BootstrapColor.SECONDARY
                                },
                                text = "Работа не назначена",
                                extraClass = ClassName("w-50 text-wrap"),
                            )
                        } else {
                            badge(
                                color = BootstrapColor.LIGHT,
                                text = "Работа назначена",
                                extraClass = ClassName("w-50 text-wrap"),
                            )
                        }
    
                        when {
                            hasData && hasConfirm -> {
                                badge(
                                    color = BootstrapColor.LIGHT,
                                    text = "Мерки заполнены и проверены",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            hasData && !hasConfirm -> {
                                badge(
                                    color = BootstrapColor.DANGER,
                                    text = "Нужно подтверждение Тани",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            hasGoogle -> {
                                badge(
                                    color = BootstrapColor.DANGER,
                                    text = "Нет дизайна и мерок",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            else -> {
                                badge(
                                    color = BootstrapColor.WARNING,
                                    text = "Нет формы от клиента",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }
                        }
                    }
                    
                    
                },
                specialSort = mapOf(
                    "1. Дата отправки" to compareBy<OrderDTO> { when {
                        it.personalData().packageTime == null && it.dataGoogle != null -> -5
                        it.personalData().packageTime == null && it.dataGoogle == null && it.allFilled -> -5
                        it.personalData().packageTime != null -> 0
                        it.personalData().packageTime == null && it.dataGoogle == null && !it.allFilled -> 1
                        else -> 0
                    } }
                        .thenBy { it.personalData().packageTime },
                    "2. Назначить работу" to compareBy { when {
                        it.hasAllWorks(workTypes, null) -> 100
                        it.allOk -> -100
                        it.allFilled -> -90
                        it.dataGoogle != null -> -50
                        else -> 0
                    } },
                    "3. Заполнить мерки" to compareBy { when {
                        it.hasAllWorks(workTypes, null) -> 100
                        it.allOk -> 50
                        it.allFilled -> -100
                        it.dataGoogle != null -> -50
                        else -> 0
                    }  },
                )
            ),
        )

        addDropdown("Работы") {
            OrderWorkList {
                this.client = props.client
                this.order = it
            }
        }


        // Тест
        addForm("Тест: Кальк", CalculatorForm)

        /*
        * 1 Дата отправки: personalData : packageTime.
        * 2 Клиент: дата когда нужен: из гугла
        * 3 менеджер: дата когда нужен:  neededTime;
        * 4 клиент дата соревнований: из Гугла
        * 5 менеджер: дата соревнований PersonalDate competitionTime
        * 6 время доставки personalData deliveryTime
        * 7 дата заказа: personalData orderTime
        * 8 Дата поступления мерок: из гугла
        * */
        addDate(
            flag = FieldFlag.ReadOnlyProp,
            title = "Дата отправки",
            value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::packageTime,
            options = TableColumnRenderOptions(
                groupNextColumns = 5,
                disabledIf = {
                    when {
                        it.personalData().packageTime != null -> EnabledResult(true)
                        it.allFilled && it.dataGoogle == null -> EnabledResult(false, "Укажите дату отправки|Нет мерок", overrideAsBadgeColor = BootstrapColor.DANGER)
                        it.dataGoogle == null -> EnabledResult(false, "Нет мерок")
                        else -> EnabledResult(false, "Укажите дату отправки", overrideAsBadgeColor = BootstrapColor.DANGER)
                    }
                }
            ),
        )//автоматически считает система
        addSpecial(FieldFlag.FromGoogleForms, "Дата когда нужен", MeasureType.SPECIAL, SpecialMeasure.NEEDED_GOOGLE)
        addSpecial(FieldFlag.FromGoogleForms, "Дата соревнования", MeasureType.SPECIAL, SpecialMeasure.COMPETITION_GOOGLE)
        addDate(FieldFlag.ReadOnlyProp, "Дата заказа", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::orderTime)
        addInt(FieldFlag.ReadOnlyProp, "Время доставки", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::deliveryTime, TableColumnRenderOptions(extraContent = { + " Дней" }))
        addDate(FieldFlag.ReadOnlyValue, "Дата поступления мерок") { this.dataGoogle?.dateGoogle }


        addText(
            flag = FieldFlag.ReadOnlyValue,
            title = "Статус",
            options = TableColumnRenderOptions(
                groupNextColumns = 3,
                extraContent = { entity ->

                    val clientColor: BootstrapColor = when {
                        entity.dataGoogle == null -> BootstrapColor.WARNING
                        else -> BootstrapColor.DANGER
                    }
    
                    div {
                        this.className = ClassName("vstack gap-1")
                        
                        if (entity.statusInfo().isDesignAll) {
                            badge(BootstrapColor.LIGHT, "Дизайн: готов")
                        } else {
                            badge(clientColor, "Дизайн: Нет")
                        }
        
                        if (entity.statusInfo().isMeasureAll) {
                            badge(BootstrapColor.LIGHT, "Мерки: готовы")
                        } else {
                            badge(clientColor, "Мерки: Нет")
                        }
        
                        if (entity.statusInfo().isMeasureAllTanya && entity.statusInfo().isDesignAllTanya) {
                            badge(BootstrapColor.LIGHT, "Таня: проверила")
                        } else {
                            if (entity.statusInfo().isDesignAll && entity.statusInfo().isMeasureAll) {
                                badge(BootstrapColor.DANGER, "Таня: Нет")
                            } else {
                                badge(BootstrapColor.SECONDARY, "Таня: Нет")
                            }
                        }
                    }
                },
                editButtonForm = MainForm
            ),
            value = { "" }
        )
        addButton(
            title = "Таня проверила",
            options = TableColumnRenderOptions(
                backgroundColor = { if (it.statusInfo().isMeasureAllTanya && it.statusInfo().isDesignAllTanya) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
            ),
            text = { if (it.statusInfo().isMeasureAllTanya && it.statusInfo().isDesignAllTanya) "+" else "Проверить!" },
            onClick = MainForm.openAction(client)
        )
        addButton(
            title = "Мерки заполнены",
            options = TableColumnRenderOptions(
                backgroundColor = { if (it.statusInfo().isMeasureAll) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
            ),
            text = { if (it.statusInfo().isMeasureAll) "+" else "Нет" },
            onClick = MainForm.openAction(client)
        )
        addButton(
            title = "Дизайн создан",
            options = TableColumnRenderOptions(
                backgroundColor = { if (it.statusInfo().isDesignAll) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
            ),
            text = { if (it.statusInfo().isDesignAll) "+" else "Нет" },
            onClick = MainForm.openAction(client)
        )

        addText(
            FieldFlag.Nullable,
            "Комментарий",
            OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::comment
        )

        addText(FieldFlag.Nullable, "Предоплата", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment)
        addCost(
            flag = FieldFlag.NotNull,
            title = "Цена",
            value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment,
            options = TableColumnRenderOptions(
                extraContent = { entity ->
                    val initialCost = entity.personalData().payment

                    val trainer = entity.personalData().trainer
                    val discountCount = trainer?.discountCount ?: 0
                    val discountPercent = initialCost * (trainer?.discountPercent ?: 0) / 100
                    val discount: Int = when {
                        discountCount < 1 -> discountPercent
                        discountPercent < 1 -> discountCount
                        else -> min(discountCount, discountPercent)
                    }.coerceIn(0, initialCost)

                    if (discount > 0) {
                        val discountPercentReal = 100 * discount / (initialCost + discount)

                        div {
                            className = ClassName("vstack gap-1 align-items-end mt-1")
                            badge(
                                color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                text = "Скидка ($discountPercentReal%): ${StringUtils.printNumber(discount)}",
                            )
                            badge(
                                color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                text = "Общая сумма заказа: ${StringUtils.printNumber(initialCost + discount)}",
                            )
                        }
                    }
                }
            )
        )
        addForm("Отправить в архив", ButtonArchiveForm)



    }


}
