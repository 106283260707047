package ru.arty_bikini.crm_frontend.ui.root

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.alert.AlertContainer
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.modal.ModalContainer
import ru.arty_bikini.crm_frontend.util.plus

val AppComponent = tryFC<ClientProps> {

    var loaded: Boolean by useState(false)
    var loggedIn: Boolean by useState(false)

    useEffectOnce {

        GlobalScope.launch {
            val result = it.client.auth.tryReconnect()

            loaded = true
            loggedIn = result

        }

    }

    if (!loaded) {

        container {
            row(Bootstrap.Utils.justifyContentCenter) {
                colMd(4) {
                    alertInfo(Bootstrap.Utils.m4) {
                        +"Загрузка..."
                    }
                }
            }
        }

    } else if (!loggedIn) {

        container {
            row(Bootstrap.Utils.justifyContentCenter) {
                colMd(4) {
                    div {
                        className = Bootstrap.card + Bootstrap.Utils.m4 + Bootstrap.Utils.shadow

                        div {
                            className = Bootstrap.cardHeader

                            +"Войдите, пожалуйста"
                        }

                        div {

                            className = Bootstrap.cardBody

                            InputPanel {
                                val login = addText("Логин")
                                val password = addPassword("Пароль")

                                onSave("Войти") {

                                    val login = get(login)
                                    val password = get(password)

                                    if (login == null || password == null) {
                                        return@onSave
                                    }

                                    GlobalScope.launch {
                                        val result = it.client.auth.tryLogin(login, password)

                                        if (!result) {
                                            window.alert("Error!")
                                        }

                                        loggedIn = result
                                    }
                                }
                            }
                        }


                    }
                }
            }
        }
    } else {
        AlertContainer {
            client = it.client
        }
        ModalContainer {
            client = it.client
        }
        AppRouter {
            client = it.client
        }
    }

}
