package ru.arty_bikini.crm_frontend.ui.input.table

import react.StateInstance
import react.useState
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.get
import ru.arty_bikini.crm_frontend.util.update

class ColumnStateHandler<T : Any>(val props: TablePanelProps<T>) {

    private val state: StateInstance<List<TableColumnState>> = useState() {
        val initial = props.columns
            .map { TableColumnState(it.id) }
            .toMutableList()

        props.columns
            .forEachIndexed { idx, col ->
                for (i in 1 .. col.options.groupNextColumns) {
                    initial[idx + i] = initial[idx + i].copy(hidden = HiddenState.HIDDEN)
                }
            }

        initial
    }

    private var sort: StateInstance<List<SortRule>> = useState {
        val cols = props.columns
            .asSequence()
            .filter { it.options.defaultSort }
            .map { SortRule(it.id, SortDirection.ASC) }
            .toMutableList()

        if (cols.isEmpty() && props.columns.isNotEmpty()) {
            cols.add(SortRule(props.columns.first().id, SortDirection.ASC))
        }

        cols
    }

    val rawSort: List<SortRule> = sort.get()

    val filter: (TablePanelEntity<T>) -> Boolean
        get() = { true }

    private val defaultComparator = StringUtils.stringOrIntComparator<TablePanelEntity<T>> { props.columns.firstOrNull()?.getAsSort(it.entity) }

    val comparator: Comparator<TablePanelEntity<T>>
        get() {
            val sort = sort.get()
            if (sort.isEmpty()) return defaultComparator

            var baseComparator = compareBy<TablePanelEntity<T>> { 0 }

            for (sortRule in sort) {
                val currCol = props.columns.firstOrNull() { it.id == sortRule.id } ?: continue

                val special: Comparator<T>? =
                    sortRule.specialName
                        ?.let { currCol.options.specialSort.get(it) }

                if (special != null) {
                    baseComparator = baseComparator.thenBy(special) { it.entity }
                } else {
                    baseComparator = baseComparator
                        .thenBy(StringUtils.stringOrIntComparator(sortRule.direction) { currCol.getAsSort(it.entity) }) { it }
                }
            }

            val defaultCol = props.columns.firstOrNull() { it.options.defaultSort }
            if (defaultCol != null) {
                baseComparator = baseComparator
                    .thenBy(StringUtils.stringOrIntComparator { defaultCol.getAsSort(it.entity) }) { it }
            }

            return baseComparator
                    .thenBy { props.columns.firstOrNull()?.getAsSort(it.entity) }

        }

    val primarySort = sort.get().firstOrNull()?.let { rule ->
        val col = props.columns.firstOrNull() { it.id == rule.id }

        col to rule.direction
    }

    fun toggleOrderBy(column: TablePanelColumnProps<T, *>) {
        sort.update { old ->

            val oldRule = old.firstOrNull() { it.id == column.id }

            val specials = column.options.specialSort.keys.sorted()

            listOf(
                SortRule(
                    column.id,
                    when {
                        oldRule == null -> SortDirection.ASC
                        oldRule.specialName != null -> SortDirection.ASC
                        oldRule.direction == SortDirection.ASC -> SortDirection.DESC
                        else -> SortDirection.ASC
                    },
                    when {
                        oldRule == null -> null
                        specials.isEmpty() -> null
                        oldRule.specialName == null && oldRule.direction == SortDirection.ASC -> null
                        oldRule.specialName == null && oldRule.direction == SortDirection.DESC -> specials.firstOrNull()
                        oldRule.specialName != null -> specials.getOrNull(specials.indexOf(oldRule.specialName) + 1)
                        else -> null
                    }
                )
            )
        }
    }

    fun setOrderBy(column: TablePanelColumnProps<T, *>, direction: SortDirection) {
        sort.update { old ->
            listOf(
                SortRule(
                    column.id,
                    direction,
                )
            )
        }
    }

    fun addOrderBy(column: TablePanelColumnProps<T, *>) {
        sort.update { old ->
            if (old.any { it.id == column.id }) {
                old.map {
                    if (it.id == column.id) {
                        return@map SortRule(column.id, if (it.direction == SortDirection.ASC) SortDirection.DESC else SortDirection.ASC)
                    } else {
                        return@map it
                    }
                }
            } else {
                old + SortRule(column.id, SortDirection.ASC)
            }
        }
    }

    fun setSpecialOrderBy(column: TablePanelColumnProps<T, *>, name: String, special: Comparator<T>) {
        sort.update { old ->
            listOf(
                SortRule(
                    column.id,
                    SortDirection.ASC,
                    specialName = name
                )
            )
        }
    }

    fun toggleHideAfter(column: TablePanelColumnProps<T, *>) {
        state.update { curr ->
            val new = curr.toMutableList()

            val index = new.indexOfFirst { it.id == column.id }
            if (index + 1 >= new.size) {
                return@update curr
            }
            val newState = when (new[index + 1].hidden) {
                HiddenState.HIDDEN -> HiddenState.SHOWN
                else -> HiddenState.HIDDEN
            }

            for (i in 1 .. column.options.groupNextColumns) {
                new[index + i] = new[index + i].copy(hidden = newState)
            }

            new
        }
    }

    fun countVisible(): Int {
        return props.columns.count { isVisible(it) }
    }

    fun isVisible(column: TablePanelColumnProps<T, *>): Boolean {
        return state.get().firstOrNull { it.id == column.id }?.hidden != HiddenState.HIDDEN
    }

}