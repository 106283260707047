package ru.arty_bikini.crm_frontend.table.admin

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.input.table.*

val UserListPage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    var users by useState { emptyList<UserDTO>() }

    fun update() {
        GlobalScope.launch {
            users = props
                .client
                .network
                .auth
                .getUsers()
                ?.users
                ?.toList()
                ?: emptyList()
        }
    }

    useEffectOnce {

        update()

    }


    TablePanelEntityAuto(props.client, "t-s-user", users, UserDTO.serializer()) {

        savePacked(props.client, "user", { it }) {


            console.log("Save handler")
            console.log(it)

            val result = props.client
                .network
                .auth
                .editUser(it)

            if (result != null) {
                users = result.users ?: emptyList()
            }


        }

        addNew = {
            props.client
                .network
                .auth
                .editUser(UserDTO().apply {
                    login = it
                })
        }

        addText(FieldFlag.Nullable, "Логин", UserDTO::login)
        addSelect(
            flag = FieldFlag.Nullable,
            title = "Группа",
            values = UserGroup.values().filter { it != UserGroup.ADMIN }.toTypedArray(),
            options = TableColumnRenderOptions(
                disabledIf = {
                    EnabledResult(it.group != UserGroup.ADMIN)
                }
            ),
            prop = UserDTO::group
        )
        addInt(FieldFlag.NotNull, "Сортировка", UserDTO::priority, options = TableColumnRenderOptions(defaultSort = true))
        addBoolean(FieldFlag.NotNull, "Показать", UserDTO::visible)
        addText(FieldFlag.Nullable, "Специализация (комментарий)", UserDTO::specialisation)
        addText(FieldFlag.Nullable, "Продуктивность (комментарий)", UserDTO::productivityComment)
        addInt(FieldFlag.ReadOnlyProp, "Продуктивность TEST", UserDTO::baseProductivity)
        addText(FieldFlag.ReadOnlyValue, "Продуктивность TEST") { this.productivity?.map { it.key.displayName + " = " + it.value }?.joinToString(",") }

        addButton(
            title = "Сбросить пароль",
            options = TableColumnRenderOptions(
                disabledIf = {
                    if (it.group == UserGroup.ADMIN) {
                        return@TableColumnRenderOptions EnabledResult(false, "Не доступно")
                    }
                    if (it.group == UserGroup.TANYA && props.client.auth.currentUser?.group != UserGroup.ADMIN) {
                        return@TableColumnRenderOptions EnabledResult(false, "Не доступно")
                    }

                    return@TableColumnRenderOptions EnabledResult(true)
                }
            ),
            onClick = {
                GlobalScope.launch {
                    props.client
                        .network
                        .auth
                        .changePassword(it)
                        ?.let {
                            window.alert(it.password ?: "???")
                        }
                }
            }
        )
    
    
    
    }


}

