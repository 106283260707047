package ru.arty_bikini.crm_frontend.form.config

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import ru.arty_bikini.crm.dto.orders.google.MeasureVariantsDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.ListToSetAdapter
import ru.arty_bikini.crm_frontend.ui.modal.EntityTrackRule
import ru.arty_bikini.crm_frontend.ui.modal.FormProps
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.useAutoReload

val GoogleVariants = createModal<OrderDataTypeDTO>(
    trackEntity = EntityTrackRule(OrderDataTypeDTO.serializer())
) { props: FormProps<OrderDataTypeDTO> ->

    val variantsData = useAutoReload<MeasureVariantsDTO> {
        props.client
            .network
            .orderData
            .getMeasureVariants()
            ?.measureVariantsDTOList
    }

    inputGroup {
        name("Название")
        value(props.entity.name)
    }
    inputGroup {
        name("Название в Google")
        value(props.entity.googleName)
    }

    val filtered = variantsData
        .filter { it.orderDataType?.id == props.entity.id }

    TablePanelEntityAuto(props.client, "f-t-s-google-variants", filtered, MeasureVariantsDTO.serializer()) {

        save = {
            props.client
                .network
                .orderData
                .editMeasureVariants(it)
        }

        addNew = {
            client
                .network
                .orderData
                .editMeasureVariants(MeasureVariantsDTO().apply {
                    this.id = 0
                    this.name = it
                    this.orderDataType = props.entity
                    this.priority = (filtered.maxOfOrNull { it.priority } ?: 0) + 1
                })
        }

        addText(FieldFlag.Nullable, "Название", value = MeasureVariantsDTO::name)
        addInt(FieldFlag.NotNull, "Сортировка", options = TableColumnRenderOptions(defaultSort = true), value = MeasureVariantsDTO::priority)
        addText(FieldFlag.Nullable, "Подсказка", MeasureVariantsDTO::description)
        addText(FieldFlag.Nullable, "Соответствует ответу в google", MeasureVariantsDTO::googleName)
        addMultiEntity(
            flag = FieldFlag.Nullable,
            title = "Доступно для продуктов",
            values = props.client.cache.productTypes,
            adapter = ::ListToSetAdapter,
            prop = { this::products },
            name = { name ?: "<error>" }
        )
        addButton("Удалить") {
            GlobalScope.launch {
                props.client
                    .network
                    .orderData
                    .deleteMeasureVariant(it)
            }
        }

    }

}
