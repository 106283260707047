package ru.arty_bikini.crm_frontend.util

import csstype.ClassName
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import ru.arty_bikini.crm_frontend.ui.input.table.SortDirection
import kotlin.js.Date

object StringUtils {

    private val digits = listOf('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '-', ',', '.')

    fun <T> stringOrIntComparator(direction: SortDirection = SortDirection.ASC, keyExtractor: (T) -> String?): Comparator<T> {
        if (direction == SortDirection.ASC) {
            return compareBy<T> { keyExtractor(it)?.takeWhile { it in digits }?.toLongOrNull() ?: Long.MAX_VALUE }
                .thenBy { keyExtractor(it) }
        } else {
            return compareByDescending <T> { keyExtractor(it)?.takeWhile { it in digits }?.toLongOrNull() ?: Long.MAX_VALUE }
                .thenByDescending { keyExtractor(it) }
        }
    }

    fun printNumber(number: Int?) = printNumber(number?.toString() ?: "0")

    fun printNumber(number: String): String {
        val tmp = number.replace(" ", "")
        val result = StringBuilder()

        for(i in tmp.indices) {
            result.append(tmp[i])
            val remaining = tmp.length - i - 1
            if (remaining > 0 && remaining % 3 == 0) {
                result.append(" ")
            }
        }

        return result.toString()
    }

    fun printLocalDate(date: DateKt?): String {
        return printLocalDate(date?.toTimestamp())
    }

    fun printLocalDate(date: Date?): String {
        return printLocalDate(date?.getTime()?.toLong())
    }

    fun printLocalDate(date: Long?): String {
        if (date == null) {
            return "<null>"
        }
        return Date(date).toISOString().takeWhile { it != 'T' }
    }

    fun printLocalDateTime(date: Date?): String {
        return printLocalDateTime(date?.getTime()?.toLong())
    }

    fun printLocalDateTime(date: Long?): String {
        if (date == null) {
            return "<null>"
        }
        return Date(date).toISOString().takeWhile { it != '.' || it != 'Z' }.replace('T', ' ')
    }

    fun isSimilar(searchQuery: String, text: String): SimilarSearchResult {

        if (searchQuery.isEmpty()) {
            return SimilarSearchResult(text, emptyQuery = true)
        }

        if (text.lowercase().contains(searchQuery.lowercase())) {
            val start = text.lowercase().indexOf(searchQuery.lowercase())
            val end = start + searchQuery.length

            val rg = SimilarSearchResultRegion(SimilarSearchResultRegionKind.FULL, start, end)

            return SimilarSearchResult(text, fullContains = true, matchRegions = listOf(rg))
        }

        var strat = 0
        val textTokens = text.lowercase()
            .split(" ")
            .map {
                val result = SimilarSearchToken(it, strat)
                strat = result.end + 1
                result
            }

        var searchTokens: MutableList<String> = ArrayList()

        if (searchQuery.contains(" ")) {
            searchTokens = searchQuery.split(" ").toMutableList()
        } else {

            var lastTokenIdx: Int? = null
            var previousChar: Char? = null
            searchQuery.forEachIndexed { idx, char ->
                if (lastTokenIdx == null || previousChar == null) {
                    lastTokenIdx = idx
                    previousChar = char
                    return@forEachIndexed // first char
                }

                if (previousChar!!.isLowerCase() && !char.isLowerCase()) {
                    // Next string part
                    searchTokens.add(searchQuery.substring(lastTokenIdx!!, idx))

                    lastTokenIdx = idx
                }

                previousChar = char
            }

            if (lastTokenIdx != null) {
                searchTokens.add(searchQuery.substring(lastTokenIdx!!))
            }
        }


        searchTokens = searchTokens.map { it.lowercase() }.toMutableList()

        console.log(searchTokens.joinToString(" | "))

        if (searchTokens.all { s -> textTokens.any { t -> t.text.contains(s) } }) {

            val regions = searchTokens.map { s ->
                val token = textTokens.first { t -> t.text.contains(s) }
                val start = token.text.indexOf(s) + token.start
                val end = start + s.length

                return@map SimilarSearchResultRegion(SimilarSearchResultRegionKind.TOKEN, start, end)
            }

            return SimilarSearchResult(text, tokensContains = true, matchRegions = regions)
        }

        return SimilarSearchResult(text)
    }

}

class SimilarSearchToken(text: String, val start: Int) {

    val text: String = text.lowercase()
    val end = start + text.length

}

class SimilarSearchResult(
    val source: String,
    val emptyQuery: Boolean = false,
    val fullContains: Boolean = false,
    val tokensContains: Boolean = false,
    val matchRegions: List<SimilarSearchResultRegion> = emptyList()
) {
    val any = emptyQuery || fullContains || tokensContains

    val render: ChildrenBuilder.() -> Unit = {
        if (matchRegions.isEmpty()) {
            + source
        } else {
            var lastPos = 0
            matchRegions
                .sortedBy { it.start }
                .forEach {
                    if (it.start <= lastPos) {
                        if (it.end <= lastPos) {
                            return@forEach
                        } else {
                            ReactHTML.span {
                                className = ClassName("bg-warning")
                                + source.substring(lastPos, it.end)
                            }
                        }
                    } else {
                        +source.substring(lastPos, it.start)
                        ReactHTML.span {
                            className = ClassName("bg-warning")
                            +source.substring(it.start, it.end)
                        }
                    }
                    lastPos = it.end
                }


            + source.substring(lastPos)
        }
    }
}

data class SimilarSearchResultRegion(val kind: SimilarSearchResultRegionKind, val start: Int, val end: Int)

enum class SimilarSearchResultRegionKind {
    FULL,
    TOKEN
}