package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.TypeWork
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.packet.auth.GetUsersResponse
import ru.arty_bikini.crm.dto.packet.auth.LoginResponse
import ru.arty_bikini.crm.dto.packet.auth.ReconnectResponse
import ru.arty_bikini.crm.dto.packet.work.GetIntervalWorkResponse
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm_frontend.util.Debug
import kotlin.js.Date

object PacketMocks {

    val enabled: Boolean = Debug.debugEnabled

    private val users = listOf(
        UserDTO().apply { id = 1; login = "admin"; group = UserGroup.ADMIN },
        UserDTO().apply { id = 2; login = "tanya"; group = UserGroup.MANAGER },
        UserDTO().apply { id = 3; login = "m1"; group = UserGroup.MANAGER },
        UserDTO().apply { id = 4; login = "m2"; group = UserGroup.MANAGER },
        UserDTO().apply { id = 5; login = "s1"; group = UserGroup.SEAMSTRESS },
        UserDTO().apply { id = 6; login = "s2"; group = UserGroup.SEAMSTRESS },
        UserDTO().apply { id = 7; login = "g1"; group = UserGroup.GLUER },
        UserDTO().apply { id = 8; login = "g2"; group = UserGroup.GLUER },
    )

    private object Constants {
        val now = Date.now().toLong()
        const val week = 7 * 24 * 60 * 60
    }

    @Suppress("IMPLICIT_CAST_TO_ANY")
    fun <T> get(url: String, query: Map<String, String>, body: Any): T? {

        return when (url) {
            "/api/auth/reconnect" -> ReconnectResponse("", ok = true, null)
            "/api/auth/login" -> {
                val ok = query.get("login") == "admin"

                LoginResponse(
                    statusCode = if (ok) "ok" else "someError",
                    ok = true,
                    displayMessage = null,
                    accessCode = if (ok) "123" else null
                )
            }
            "/api/auth/get-users" -> {
                GetUsersResponse(
                    users,
                    "ok"
                )
            }
            "/api/work/get-interval-work" -> GetIntervalWorkResponse(
                "ok",
                listOf(
                    WorkDTO().apply {
                        id = 1
                        order = OrderDTO().apply { id = 1; name = "123" }
                        user = users.first { it.group == UserGroup.SEAMSTRESS }
                        interval = IntervalDTO().apply { id = 1 }
                        works = emptyList()
                    },
                    WorkDTO().apply {
                        id = 2
                        order = OrderDTO().apply { id = 1; name = "123" }
                        user = users.first { it.group == UserGroup.GLUER }
                        interval = IntervalDTO().apply { id = 2 }
                        works = emptyList()
                    }
                ),
                listOf(
                    IntervalDTO().apply { id = 1; dateStart = Constants.now - Constants.week; dateFinish = Constants.now },
                    IntervalDTO().apply { id = 2; dateStart = Constants.now; dateFinish = Constants.now + Constants.week }
                )
            )
            else -> null
        }.unsafeCast<T?>()
    }

}
