package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import react.dom.html.ReactHTML
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.production.worksTMP
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*

val WorksFormPart = tryFC<FormPartProps> { props ->

    FormInput(props.client, props.order) {

        readOnly = props.readOnly

        onSave {
            props
                .client
                .network
                .order
                .saveOrder(it)
        }

        content { entity ->

            cardGreenCollapse("Работы", ClassName("vstack gap-2"), defaultVisible = false) {


                fun show(group: UserGroup) {

                    row {
                        colSm(4) {
                            + "Сотрудник"
                        }
                        colSm(4) {
                            + "Работы"
                        }
                        colSm(4) {
                            + "До срока"
                        }
                    }
                    entity
                        .worksTMP
                        ?.filter { it.user?.group == group }
                        ?.forEach {
                            row {
                                colSm(4) {
                                    +it.user?.login.orEmpty()
                                }
                                colSm(4) {
                                    it.works?.forEach {
                                        badge(BootstrapColor.LIGHT, it.name ?: "")
                                    }
                                }
                                colSm(4) {
                                    + StringUtils.printLocalDate(it.interval?.dateFinish)
                                }
                            }
                        }
                }
                ReactHTML.h4 { +"Пошив" }
                show(UserGroup.SEAMSTRESS)

                ReactHTML.h4 { +"Расклейка" }
                show(UserGroup.GLUER)

            }

        }
    }
}