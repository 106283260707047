package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.dict.TrainerDTO
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.packet.order.*
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.debug.GetOrdersPageRequest
import ru.arty_bikini.crm_frontend.debug.GetOrdersPageResponse
import ru.arty_bikini.crm_frontend.debug.OrderFilter

class OrderService(clientCore: ClientCore) : BaseService(clientCore) {

    suspend fun getLeads(): GetClientsResponse? {
        return call<GetClientsResponse>("/api/order/get-leads").body
    }

    suspend fun getClients(): GetClientsResponse? {
        return call<GetClientsResponse>("/api/order/get-clients").body
    }

    suspend fun getOrder(order: OrderDTO): GetOrderResponse? {
        return call<GetOrderResponse, GetOrderRequest>("/api/order/get-order", body = GetOrderRequest(idOrder = order.id)).body
    }

    suspend fun saveOrder(entity: OrderDTO): EditClientResponse? {
        return call<EditClientResponse, EditClientRequest>("/api/order/edit-client", body = EditClientRequest(entity)).body
    }

    suspend fun addOrder(name: String, clientType: ClientType): AddClientResponse? {
        return call<AddClientResponse>(
            url = "/api/order/add-client",
            query = mapOf(
                "name" to name,
                "type" to clientType.name
            )
        ).body
    }

    suspend fun getClient(entity: TrainerDTO): GetOrderByTrainerResponse? {
        return call<GetOrderByTrainerResponse, GetOrderByTrainerRequest>(
            "/api/order/get-order-by-trainet",
            body = GetOrderByTrainerRequest(idTrainer = entity.id)
        ).body
    }

    suspend fun getArchive(page: Int): GetArchiveResponse? {
        return call<GetArchiveResponse>(
            "/api/order/get-archive", query = mapOf(
                "page" to page.toString()
            )
        ).body
    }

    suspend fun getOrdersPage(filter: OrderFilter): ServerResponse<PageDTO<OrderDTO>> {
        return call<GetOrdersPageResponse, GetOrdersPageRequest>(
            "/api/order/get-orders-page",
            body = GetOrdersPageRequest(filter)
        ).map { data }
    }

}

