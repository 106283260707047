package ru.arty_bikini.crm_frontend.table.config

import kotlinx.browser.window
import kotlinx.serialization.builtins.ArraySerializer
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import react.useState
import ru.arty_bikini.crm.dto.enums.ColumnImportTarget
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.measure.DataDisplayCategory
import ru.arty_bikini.crm.dto.orders.google.MeasureVariantsDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.form.config.GoogleVariants
import ru.arty_bikini.crm_frontend.form.config.GoogleVariantsImport
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.ListToSetAdapter
import ru.arty_bikini.crm_frontend.util.useAutoReload

val OrderDataTypeTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN)
) { props ->

    val variants = useAutoReload {
        props
            .client
            .network
            .orderData
            .getMeasureVariants()
            ?.measureVariantsDTOList
            ?.sortedBy { it.name }
    }

    val all = useAutoReload<OrderDataTypeDTO> {
        props
            .client
            .network
            .orderData
            .getDataTypes()
            ?.types
            ?.sortedBy { it.name }
            ?.sortedBy { it.googleColumn }
    }

    cardLightShadow {
        inputGroup {
            btn(BootstrapColor.SECONDARY, "Экспорт") {
                val json = Json

                val data1 = json.encodeToString(ListSerializer(OrderDataTypeDTO.serializer()), all)
                val data2 = json.encodeToString(ListSerializer(MeasureVariantsDTO.serializer()), variants)
                val data = json.encodeToString(ListSerializer(String.serializer()), listOf(data1, data2))

                window.navigator.clipboard.writeText(data)
                    .then { props.client.event.fireEvent(ShowAlertEvent(text = "Сохранено в буфер обмена")) }
                    .catch { props.client.event.fireEvent(ShowAlertEvent(BootstrapColor.DANGER, text = "Нет доступа к буферу обмена")) }
            }
            btn(BootstrapColor.SECONDARY, "Импорт") {

                window.navigator.clipboard.readText()
                    .then {
                        props.client.event.showModal(GoogleVariantsImport, it)
                    }
                    .catch { props.client.event.fireEvent(ShowAlertEvent(BootstrapColor.DANGER, text = "Нет доступа к буферу обмена")) }


            }
        }
    }

    TablePanelEntityAuto<OrderDataTypeDTO>(props.client, "t-dict-order-data-type", all, OrderDataTypeDTO.serializer()) {

        save = {
            props
                .client
                .network
                .orderData
                .saveDataType(it)
        }

        addNew = {
            props
                .client
                .network
                .orderData
                .saveDataType(OrderDataTypeDTO().apply {
                    this.name = it
                    this.googleName = "<Не импортируется>"
                })
        }

        addText(FieldFlag.Nullable, "Название", OrderDataTypeDTO::name)
        addSelect(FieldFlag.Nullable, "Серверный обработчик", ColumnImportTarget.values(), OrderDataTypeDTO::target)
        addSelect(FieldFlag.Nullable, "Отображать в группе", DataDisplayCategory.values(), OrderDataTypeDTO::displayCategory)
        addSelect(
            flag = FieldFlag.Nullable,
            title = "Положение (специальное)",
            values = SpecialMeasure.values(),
            options = TableColumnRenderOptions(disabledIf = {
                when (it.displayCategory) {
                    DataDisplayCategory.SPECIAL -> EnabledResult(true)
                    else -> EnabledResult(false, "Не применимо")
                }
            }),
            get = { SpecialMeasure.values().firstOrNull { it.name == displayPosition } },
            set = { v -> displayPosition = v?.name },
        )
        addText(
            flag = FieldFlag.Nullable,
            title = "Положение в группе",
            value = OrderDataTypeDTO::displayPosition,
            options = TableColumnRenderOptions(disabledIf = {
                when (it.displayCategory) {
                    DataDisplayCategory.MEASURES_UP, DataDisplayCategory.MEASURES_DOWN, DataDisplayCategory.MEASURES_OTHER -> EnabledResult(true)
                    else -> EnabledResult(false, null)
                }
            })
        )

        addMultiEntity(
            flag = FieldFlag.Nullable,
            title = "Товар",
            values = client.cache.productTypes,
            adapter = ::ListToSetAdapter,
            prop = { this::products },
            name = { name ?: "<null>" }
        )
        addForm(
            title = "Варианты",
            form = GoogleVariants,
            options = TableColumnRenderOptions(
                extraContent = { type ->
                    val count = variants.count { it.orderDataType == type }
                    badge(if (count > 0) BootstrapColor.INFO else BootstrapColor.LIGHT, count.toString())
                }
            )
        )
        addTextArea(FieldFlag.Nullable, "Справка", value = OrderDataTypeDTO::comment)
        addText(
            flag = FieldFlag.ReadOnlyProp,
            title = "Импорт из",
            value = OrderDataTypeDTO::googleName,
            options = TableColumnRenderOptions(maxWidthPx = 250)
        )
        addInt(FieldFlag.ReadOnlyProp, "Импорт из (Номер колонки)", OrderDataTypeDTO::googleColumn)

    }

}
