package ru.arty_bikini.crm_frontend.ui.input.table

import csstype.ClassName
import org.w3c.dom.HTMLSelectElement
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.textarea
import react.useEffect
import react.useState
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapButton
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.row
import ru.arty_bikini.crm_frontend.ui.input.element.MultiSelectElement
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.plus
import ru.arty_bikini.crm_frontend.util.useCache

external interface TablePanelItemProps<T : Any, V> : ClientProps {

    var column: TablePanelColumnProps<T, V>
    var item: T

    var itemId: String

    var onUpdate: (Boolean) -> Unit
}

val TablePanelItem = tryFC<TablePanelItemProps<*, *>> {

    val cache = it.column.cache
    if (cache != null) {
        val cacheValue = useCache(cache)
//        console.log(cacheValue)
    }

    fun <T : Any, V> setVal(props: TablePanelItemProps<T, V>, raw: Any?, newValue: V?, force: Boolean) {

        if (!props.column.allowNull && newValue == null) {
            console.log("Parsing error: $raw")
            return
        }

        props.column.set(props.item, newValue as V)
        props.onUpdate(force)
    }

    fun <T : Any, V> bounded(props: TablePanelItemProps<T, V>) {

        var currentId by useState { props.itemId }
        var currentValue by useState { props.column.get(props.item) }

        useEffect(props.column.getAsString(props.item)) {
            currentValue = props.column.get(props.item)
        }

        if (currentId != props.itemId) {
            currentId = props.itemId
            currentValue = props.column.get(props.item)
        }

        div {

            val bgColor = props.column.options.backgroundColor(props.item)
            val bgColorName = bgColor?.let { ClassName("bg-" + it.suffix) }
            val disabledInfo = props.column.options.disabledIf(props.item)

            className = ClassName("pe-0") + props.column.options.extraClasses(props.item)

            when (props.column.type) {
                TablePanelColumnType.BUTTON, TablePanelColumnType.BUTTON_DROPDOWN -> {}
                else -> {
                    className = className + (bgColorName ?: ClassName(""))
                }
            }

            title = when (props.column.type) {
                TablePanelColumnType.TEXT_LINE,
                TablePanelColumnType.TEXT_AREA,
                TablePanelColumnType.INT,
                TablePanelColumnType.CHECKBOX,
                TablePanelColumnType.DATE,
                TablePanelColumnType.DATE_TIME, -> {
                    props.column.getAsString(props.item)
                }
                TablePanelColumnType.SELECT, -> {
                    props.column.getAsString(props.item)
                }
                TablePanelColumnType.MULTI_SELECT, -> {
                    props.column.getAll(props.item).joinToString(", ") { props.column.asString(it) }
                }
                else -> null
            }

            if (disabledInfo.enabled == false) {
                if (disabledInfo.overrideAsBadge != null) {
                    div {
                        className = ClassName("vstack gap-1")

                        disabledInfo.overrideAsBadge.split("|").forEach { part ->
                            badge(disabledInfo.overrideAsBadgeColor, part)
                        }
                    }
                } else {
                    span {
                        +props.column.getAsString(props.item)
                    }
                }
                return@div
            }

            when (props.column.type) {
                TablePanelColumnType.TEXT_LINE,
                TablePanelColumnType.INT,
                TablePanelColumnType.DATE,
                TablePanelColumnType.DATE_TIME,
                -> {
                    className = className + ClassName("text-nowrap overflow-hidden")
                    
                    if (props.column.readOnly) {
                        span {
                            +props.column.getAsString(props.item)
                        }
                    } else {
                        input {

                            className = ClassName("form-control form-control-sm p-1")

                            value = props.column.asString(currentValue)

                            type = props.column.converter?.inputType

                            //$('body').on('focus', '[contenteditable]', function() {
                            //    const $this = $(this);
                            //    $this.data('before', $this.html());
                            //}).on('blur keyup paste input', '[contenteditable]', function() {
                            //    const $this = $(this);
                            //    if ($this.data('before') !== $this.html()) {
                            //        $this.data('before', $this.html());
                            //        $this.trigger('change');
                            //    }
                            //});
                            onChange = event@{
                                val newValue = props.column.fromInput(it.target.value)
                                if (newValue != null) {
                                    currentValue = newValue
                                }
                                return@event // Временно откдючено

                                val raw = it.target.value ?: "<null>"
                                console.log(raw)

                                val value = props.column.fromInput(raw)

                                setVal(props, raw, value, false)
                            }
                            onBlur = event@{

                                val raw = it.target.value ?: "<null>"
                                console.log(raw)

                                val value = props.column.fromInput(raw)

                                setVal(props, raw, value, true)

                            }
                        }
                    }

                    val modal = props.column.options.editButtonForm
                    if (modal != null) {
                        
                        onClick = {
                            props.client.event.showModal(modal, props.item)
                        }
                    }
                }

                TablePanelColumnType.TEXT_AREA,
                -> {
                    className = className + ClassName("text-nowrap overflow-hidden")

                    if (props.column.readOnly) {
                        span {
                            +props.column.getAsString(props.item)
                        }
                    } else {
                        textarea {

                            className = ClassName("form-control form-control-sm p-1")

                            value = props.column.asString(currentValue)

                            rows = 3

                            onChange = event@{
                                val newValue = props.column.fromInput(it.target.value)
                                if (newValue != null) {
                                    currentValue = newValue
                                }
                                return@event
                            }
                            onBlur = event@{

                                val raw = it.target.value ?: "<null>"
                                console.log(raw)

                                val value = props.column.fromInput(raw)

                                setVal(props, raw, value, true)

                            }
                        }
                    }

                    val modal = props.column.options.editButtonForm
                    if (modal != null) {

                        onClick = {
                            props.client.event.showModal(modal, props.item)
                        }
                    }
                }

                TablePanelColumnType.CHECKBOX
                -> {
                    className = className + ClassName("text-nowrap overflow-hidden")

                    div {
                        className = ClassName("form-check")

                        input {

                            className = ClassName("form-check-input")

                            type = props.column.converter?.inputType
                            readOnly = props.column.readOnly
                            disabled = props.column.readOnly

                            checked = (currentValue as? Boolean == true)
                        }

                        label {
                            className = ClassName("form-check-label")

                            + props.column.asString(currentValue)
                        }

                        onClick = {
                            val newVal = !(currentValue as? Boolean == true)
                            setVal(props, newVal.toString(), newVal as V, true)
                        }
                    }

                }

                TablePanelColumnType.BUTTON,
                TablePanelColumnType.BUTTON_DROPDOWN -> {
                    button {
                        val color = bgColor ?: BootstrapColor.SECONDARY
                        className = BootstrapButton.btnColorless + color.let { ClassName("btn-outline-${it.suffix}") }

                        +props.column.getAsString(props.item)

                        if (props.column.type == TablePanelColumnType.BUTTON_DROPDOWN) {
                            asDynamic()["data-bs-toggle"] = "dropdown"
                            asDynamic()["data-bs-auto-close"] = "outside"
                        }

                        onClick = {
                            props.column.set(props.item, null as V)
                        }
                    }
                }

                TablePanelColumnType.SELECT -> {
                    select {
                        val current = props.column.get(props.item)
                        val currentKey = props.column.asKey(current)

                        className = listOf<ClassName?>(
                            ClassName("form-select form-select-sm"),
                            if (current == null) ClassName("text-muted") else null
                        ).reduce { a, b -> a + (b ?: ClassName("")) }


                        ReactHTML.option {
                            value = "<null>"

                            className = ClassName("text-muted")

                            +" "

                            selected = (current == null)
                        }

                        if (current != null && props.column.variants?.none { props.column.asKey(it) == props.column.asKey(current) } == true) {
                            ReactHTML.option {
                                className = ClassName("text-muted")

                                this.value = "<error>"

                                this.disabled = true

                                +(props.column.asString(current) + " (Устаревшее)")

                                selected = true

                            }
                        }

                        props.column.variants?.forEach { variant ->

                            ReactHTML.option {
                                value = (props.column).asKey(variant)

                                +(props.column).asString(variant)

                                selected = ((props.column).asKey(variant) == (props.column).asKey(current))
                            }

                        }

                        onChange = event@{

                            val raw = (it.target as? HTMLSelectElement)?.value ?: "<unset>"
                            console.log(raw)

                            if (raw == currentKey) {
                                return@event
                            }

                            val value = props.column.fromInput(raw)

                            setVal(props, raw, value, true)
                        }

                    }
                }

                TablePanelColumnType.MULTI_SELECT -> {
                    MultiSelectElement<T, V & Any>(
                        item = props.item,
                        variants = props.column.variants ?: emptyList(),
                        getAll = props.column.getAll,
                        setAll = { e, v ->
                            props.column.setAll(e, v)
                            props.onUpdate(true)
                        },
                        asKey = props.column::asKey,
                        toText = props.column::asString
                    )
                }

            }

            props.column.options.extraContent(this, props.item)
        }

    }
    bounded(it as TablePanelItemProps<Any, Any?>)
}

