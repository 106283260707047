package ru.arty_bikini.crm_frontend.table

import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.LeadInfoDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.debug.OrderFilter
import ru.arty_bikini.crm_frontend.form.main.ArchiveMainForm
import ru.arty_bikini.crm_frontend.form.main.MainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.leadInfo
import ru.arty_bikini.crm_frontend.util.personalData

val ArchiveTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA, UserGroup.MANAGER)
) { props ->


    TablePanelOrderPage(
        client = props.client,
        hintId = "t-archive",
        data = { filter -> this.order.getOrdersPage(filter).body },
        defaultFiler = OrderFilter().apply { this.type = ClientType.ARCHIVE }
    ) {

        addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name, TableColumnRenderOptions(editButtonForm = ArchiveMainForm))
        addDateTime(FieldFlag.ReadOnlyProp, "Дата появления лида", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::createdTime)
        addDateTime(FieldFlag.ReadOnlyProp, "Дата предоплаты", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::orderTime)
        addDateTime(FieldFlag.ReadOnlyProp, "Дата попадания в архив", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::archiveTime)
        addText(FieldFlag.ReadOnlyProp, "Комментарий", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::comment)
        addText(FieldFlag.ReadOnlyProp, "Комментарий слива", OrderDTO::leadInfo or ::LeadInfoDTO then LeadInfoDTO::commentArchive)
        addText(FieldFlag.ReadOnlyValue, "А,В,С") { this.leadInfo?.hasMany?.displayName }
        addText(FieldFlag.ReadOnlyValue, "Г,Т,Х") { this.leadInfo?.coldHot?.displayName }

    }


}
