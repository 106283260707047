package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.dict.*
import ru.arty_bikini.crm.dto.packet.dict.*
import ru.arty_bikini.crm.dto.work.WorkTypeDTO
import ru.arty_bikini.crm_frontend.ClientCore

class DictService(clientCore: ClientCore) : BaseService(clientCore) {


    //trainer
    suspend fun getTrainers(): GetTrainersResponse? {
        return call<GetTrainersResponse>("/api/dict/get-trainers").body
    }
    suspend fun addTrainer(entity: TrainerDTO): AddTrainersResponse? {
        val name = entity.name ?: error("name is null")
        return call<AddTrainersResponse, AddTrainersRequest>("/api/dict/add-trainer", body = AddTrainersRequest(name)).body
    }

    suspend fun editTrainer(entity: TrainerDTO): EditTrainersResponse? {
        return call<EditTrainersResponse, EditTrainersRequest>("/api/dict/edit-trainer", body = EditTrainersRequest(entity)).body
    }

    //product
    suspend fun getProductTypes(): GetProductTypesResponse? {
        return call<GetProductTypesResponse>("/api/dict/get-product-types").body
    }

    suspend fun editProductType(entity: ProductTypeDTO): EditProductTypeResponse? {
        return call<EditProductTypeResponse, EditProductTypeRequest>("/api/dict/edit-product-type", body = EditProductTypeRequest(entity)).body
    }

    //stone
    suspend fun getRhinestoneType(): GetRhinestoneTypeResponse? {
        return call<GetRhinestoneTypeResponse>("/api/dict/get-rhinestone-type").body
    }

    suspend fun editRhinestoneType(entity: RhinestoneTypeDTO): EditRhinestoneTypeResponse? {
        return call<EditRhinestoneTypeResponse, EditRhinestoneTypeRequest>("/api/dict/edit-rhinestone-type", body = EditRhinestoneTypeRequest(entity)).body
    }

    // express
    suspend fun getExpress(): GetExpressResponse? {
        return call<GetExpressResponse>("/api/dict/get-express").body
    }

    suspend fun addExpress(entity: ExpressDTO): AddExpressResponse? {
        return call<AddExpressResponse, AddExpressRequest>("/api/dict/add-express", body = AddExpressRequest(entity)).body
    }

    suspend fun editExpress(entity: ExpressDTO): EditExpressResponse? {
        return call<EditExpressResponse, EditExpressRequest>("/api/dict/edit-express", body = EditExpressRequest(entity)).body
    }

    // price
    suspend fun getPrice(): GetPriceResponse? {
        return call<GetPriceResponse>("/api/dict/get-price").body
    }

    suspend fun addPrice(entity: PriceDTO): AddPriceResponse? {
        return call<AddPriceResponse, AddPriceRequest>("/api/dict/add-price", body = AddPriceRequest(entity)).body
    }

    suspend fun editPrice(entity: PriceDTO): EditPriceResponse? {
        return call<EditPriceResponse, EditPriceRequest>("/api/dict/edit-price", body = EditPriceRequest(entity)).body
    }

    // straps

    suspend fun getStraps(): GetStrapsResponse? {
        return call<GetStrapsResponse>("/api/dict/get-straps").body
    }

    suspend fun addStraps(entity: StrapsDTO): AddStrapsResponse? {
        return call<AddStrapsResponse, AddStrapsRequest>("/api/dict/add-straps", body = AddStrapsRequest(entity)).body
    }

    suspend fun editStraps(entity: StrapsDTO): EditStrapsResponse? {
        return call<EditStrapsResponse, EditStrapsRequest>("/api/dict/edit-straps", body = EditStrapsRequest(entity)).body
    }


    suspend fun getWorkTypes(): GetWorkTypeResponse? {
        return call<GetWorkTypeResponse>("/api/dict/get-work-type").body
    }

    suspend fun addWorkType(entity: WorkTypeDTO): AddWorkTypeResponse? {
        return call<AddWorkTypeResponse, AddWorkTypeRequest>("/api/dict/add-work-type", body = AddWorkTypeRequest(entity)).body
    }

    suspend fun editWorkType(entity: WorkTypeDTO): EditWorkTypeResponse? {
        return call<EditWorkTypeResponse, EditWorkTypeRequest>("/api/dict/edit-work-type", body = EditWorkTypeRequest(entity)).body
    }
}
