package ru.arty_bikini.crm_frontend

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import ru.arty_bikini.crm_frontend.debug.Test
import ru.arty_bikini.crm_frontend.event.EventModule
import ru.arty_bikini.crm_frontend.network.EntitySaveHelper
import ru.arty_bikini.crm_frontend.network.NetworkModule

class ClientCore {

    init {
        window.asDynamic()["clientCore"] = this

        GlobalScope.launch {
            Test.test()
        }
    }

    val auth = AuthModule(this)
    val network = NetworkModule(this)
    val save = EntitySaveHelper()
    val cache = CacheModule(network)
    val event = EventModule(this)
    val settings = SettingsModule(this)

}

